import React from "react";
import ReactApexChart from "react-apexcharts";

function DonutDemo({ sellerForGraph, sellerGraphCount }) {
  // Response
  // sellerForGraph = ["X Studios", "Pictures"];
  // sellerGraphCount = [2, 5];

  var options = {
    series: sellerGraphCount,
    chart: {
      width: "100%",
      height: "100%",
      type: "donut",
    },
    labels: sellerForGraph,
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + "%"];
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={options.series}
          type="donut"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default DonutDemo;
