import React, { useContext } from "react";
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ViewOffCanvance.css";
import { Context } from "../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select";
import { CancelButton, SaveButton } from "../../common/Button";
import ModalSave from "../../common/ModelSave";

library.add(fas);

const ViewOffCanvance = (props) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const dataToSend = new FormData();
      dataToSend.append("stage", data?.stage?.value);
      dataToSend.append("email", getValues("email"));
      const response = await postData(
        `/employee/seller/request/${props.id}`,
        dataToSend
      );

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      props.getDataAll();
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [data, setData] = useState();
  const getSellerData = async () => {
    const res = await getData(`/employee/seller/${props.id}`);
    setData(res?.data);
    reset(res?.data);
  };

  useEffect(() => {
    getSellerData();
  }, [props.id]);

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%", height: "auto" }}
          placement={"end"}
          onHide={props.handleClose}
          className="offcan prodcuttttt-offcanvas"
        >
          <div className="tabss-main">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <Card className="shadow-lg p-3 mb-5  rounded card card">
                <Card.Body>
                  <Card.Title>
                    <h3>Vender Accept Or Reject </h3>
                  </Card.Title>

                  <Col md={4}>
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Take an action</Form.Label>
                        <InputGroup>
                          <Controller
                            name="stage" // name of the field
                            control={control}
                            rules={{ required: "Take an Action" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.stage
                                      ? "red"
                                      : baseStyles.borderColor,
                                  }),
                                }}
                                options={[
                                  {
                                    value: "Approved",
                                    name: "option",
                                    label: "Approve",
                                  },
                                  {
                                    value: "Rejected",
                                    name: "option",
                                    label: "Reject",
                                  },
                                ]}
                              />
                            )}
                          />
                        </InputGroup>
                        {errors.stage && (
                          <p style={{ color: "red" }}>{errors.stage.message}</p>
                        )}
                      </Form.Group>
                    </div>
                  </Col>

                  <Row className="mt-5 pb-3">
                    <div className="d-flex justify-content-center">
                      <CancelButton
                        name={"Back"}
                        handleClose={props.handleClose}
                      />

                      <SaveButton
                        name={"save"}
                        handleSubmit={handleSubmit(onSubmit)}
                      />
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Offcanvas.Body>
          </div>
        </Offcanvas>
      </div>
      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default ViewOffCanvance;
