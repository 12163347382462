import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../../common/ModelDelete";

import { AddButton, EditButton, DeletButton } from "../../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SettingContent from "../SettingContent/SettingContent";
import { Button } from "react-bootstrap";
import OffcanvasCon from "../../OffcanvasCon/OffcanvasCon";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    IMG_URL,
    isAllow,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const getDataAll = async () => {
    const response = await getData(
      `/home/app-setup?page=${currentPage}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/home/app-setup/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/home/app-setup/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"App Setup"} link={"/seller/s-banner"} />
        <section className="AdvanceDashboard">
          <div className="row ">
            <div className="col-lg-12">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio ">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="row"></div>
                      <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="add">
                          {/* <div className="add">
                        <Link
                          // to="/home/app-setup/add"
                          type="button"
                          className="btn btn-add pe-3"
                        >
                          <div onClick={() => handleShow()}>
                            <img src={plus} className="plus me-2 ms-0" alt="" />
                            Add App Setup
                          </div>
                        </Link>
                      </div> */}
                        </div>
                      </div>

                      {/* <div className="row mt-4">
                      <div className="  col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="  col-lg-12  col-md-12 col-12">
                        <div className="d-flex justify-content-end">
                          <div className="sowing me-2">
                            <p className="show mt-2">{`Showing ${Math.min(
                              (currentPage - 1) * perPage + 1
                            )} to ${Math.min(
                              currentPage * perPage,
                              data?.data?.totalEntries
                            )} of ${data?.data?.totalEntries} entries`}</p>
                          </div>
                          <div className="num me-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                            />
                          </div>
                          </div> <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2"><div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div>

                          </div> <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2"><div className="Search-1">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr">S. No</th>}
                              {visible.col2 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "500px" }}
                                >
                                  Name{" "}
                                </th>
                              )}
                              {visible.col3 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "500px" }}
                                >
                                  Contact No
                                </th>
                              )}
                              {visible.col4 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "500px" }}
                                >
                                  Email
                                </th>
                              )}
                              {visible.col5 && (
                                <th className="tax-name">Address</th>
                              )}
                              {visible.col6 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "400px" }}
                                >
                                  Platfrom Charges
                                </th>
                              )}{" "}
                              {visible.col8 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "400px" }}
                                >
                                  GST
                                </th>
                              )}
                              {visible.col7 && (
                                <th className="active">Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && <td>{d?.name}</td>}
                                  {visible.col3 && <td>{d?.contact_no}</td>}
                                  {visible.col4 && <td>{d?.email}</td>}
                                  {visible.col5 && <td>{d?.address}</td>}
                                  {visible.col6 && (
                                    <td>{d?.platform_charges} %</td>
                                  )}{" "}
                                  {visible.col8 && <td>{d?.gst} %</td>}
                                  {/* {visible.col6 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )} */}
                                  {visible.col7 && (
                                    <td>
                                      <div className="d-flex">
                                        <EditButton
                                          handleShow1={handleShow1}
                                          id={d?.id}
                                        />

                                        {/* <DeletButton
                                        showDeleteRecord={showDeleteRecord}
                                        id={d?.id}
                                        name={d?.name}
                                      /> */}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
