import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import plus from "../../Components/assets/icons/a1.png";
import colunms from "../../Components/assets/icons/LINES.png";
import search1 from "../../Components/assets/icons/search.png";
import top from "../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import ModalDelete from "../common/ModelDelete";
import ModelBulkUpload from "../common/ModelBulkUpload";
import { AddButton, EditButton, DeletButton } from "../common/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

import { Button } from "react-bootstrap";
import OffcanvasCon from "../OffcanvasCon/OffcanvasCon";
import DataTable from "datatables.net";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    Per_Page_Dropdown,
    postData,
    getDownloadDataExcel,
    isAllow,
    IMG_URL,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [showoff, setShowoff] = useState(false);
  const [searchDate, setSearchDate] = useState("");
  const [searchDateTo, setSearchDateTo] = useState("");
  const getDataAll = async () => {
    const response = await getData(
      `/wallet-history?page=${currentPage}&per_page=${perPage}&term=${search}&from=${
        searchDate || ""
      }&to=${searchDateTo || ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);

    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    searchDateTo,
    searchDate,
    search,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/wallet-history/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/subscription/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  function formatDate(dateString) {
    if (!dateString) {
      return "Date not available"; // Fallback if the date is null or undefined
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid date"; // Handle invalid date
    }

    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  // Example usage
  // Output: '9 Oct 2024' or 'Date not available'

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Wallet History"} link={"/subscription"} />
        <section className="AdvanceDashboard">
          <div className="row">
            <div className="col-xxl-12 col-xl-12  ">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio ">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="row">
                        {/*  <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                         <div className="add">
                            {isAllow?.includes(54) ? (
                              <Link
                                // to="/masters/category/add"
                                type="button"
                                className="btn btn-add pe-3"
                              >
                                <div onClick={() => handleShow()}>
                                  <img
                                    src={plus}
                                    className="plus me-2 ms-0"
                                    alt=""
                                  />
                                  Add Additional Support
                                </div>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </div> 
                        </div>*/}
                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                          <div className="add">
                            <div className="dropdown">
                              <button
                                className="btn btn-columns dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={colunms}
                                  className="columns me-2 "
                                  alt=""
                                />
                                Column Selection
                                <img src={top} className="top ms-1" alt="" />
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col1")
                                    }
                                    href="#"
                                  >
                                    S. No
                                    {visible?.col1 ? (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col2")
                                    }
                                    href="#"
                                  >
                                    Name
                                    {visible?.col2 ? (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>{" "}
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col3")
                                    }
                                    href="#"
                                  >
                                    Status
                                    {visible?.col3 ? (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col4")
                                    }
                                    href="#"
                                  >
                                    Action
                                    {visible?.col4 ? (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="border-line mt-3"></div>
                        <div className="row mt-4">
                          <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-12 col-12">
                            <div className="d-flex">
                              <div className="show me-2">
                                <p className="show mt-1">Show</p>
                              </div>
                              <div className="number me-2">
                                <select
                                  className="form-select form-select-sm"
                                  aria-label=".form-select-sm example"
                                  onChange={(e) => setperPage(e.target.value)}
                                >
                                  {option?.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="entries">
                                <p className="show mt-1">entries</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-12 col-xl-12  col-lg-12  col-md-12 col-12">
                            <div className="row">
                              <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                                <div className="sowing me-2">
                                  <p className="show mt-2">{`Showing ${Math.min(
                                    (currentPage - 1) * perPage + 1
                                  )} to ${Math.min(
                                    currentPage * perPage,
                                    data?.data?.totalEntries
                                  )} of ${
                                    data?.data?.totalEntries
                                  } entries`}</p>
                                </div>
                              </div>
                              from
                              <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                                <div className="num me-2">
                                  {/* <label  className="form-label">From</label> */}
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="from"
                                    value={searchDate}
                                    onChange={(e) =>
                                      setSearchDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              to
                              <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                                <div className="num me-2">
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="to"
                                    value={searchDateTo}
                                    min={searchDate}
                                    onChange={(e) =>
                                      setSearchDateTo(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-xxl-4 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                                <div className="num me-2 text-end">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id=""
                                    placeholder="Customer/Vender/studio "
                                    value={search}
                                    onChange={(e) => {
                                      setSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-xxl-1 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                                <div className="Search-1">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setSearch("");
                                      setSearchDate("");
                                      setSearchDateTo("");

                                      setReset(!reset);
                                    }}
                                    className="btn btn-reset w-100"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                              {/* <div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}
                            </div>
                          </div>
                        </div>

                        {/* {isAllow?.includes(206) ? (
                          <div className="add">
                            <button
                              className="btn btn-add pe-3"
                              onClick={() =>
                                document.getElementById("CategoryFile").click()
                              }
                            >
                              Bulk Upload
                            </button>

                            <input
                              type="file"
                              id="CategoryFile"
                              onChange={(e) => {
                                BulkUpload(e);
                              }}
                              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              style={{ display: "none" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {isAllow?.includes(206) ? (
                          <div className="add">
                            <Link type="button" className="btn btn-add pe-3">
                              <div onClick={Sample}>Sample</div>
                            </Link>
                          </div>
                        ) : (
                          <></>
                        )} */}
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && <th className="sr">S. No</th>}
                              {visible.col2 && (
                                <th className="tax-name">Vender Name </th>
                              )}{" "}
                              {visible.col3 && (
                                <th className="tax-name">Customer Name </th>
                              )}
                              {visible.col4 && (
                                <th className="tax-name">Studio Name</th>
                              )}{" "}
                              {visible.col5 && (
                                <th className="tax-name">Total Amount</th>
                              )}
                              {visible.col6 && (
                                <th className="tax-name">Vender Amount</th>
                              )}{" "}
                              {visible.col7 && (
                                <th className="tax-name">Admin Amount</th>
                              )}{" "}
                              {visible.col8 && (
                                <th className="tax-name">
                                  Platform Percentage
                                </th>
                              )}
                              {visible.col10 && (
                                <th className="active">Payment Status</th>
                              )}
                              {visible.col9 && <th className="active">Date</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && (
                                    <td>{d?.vender_name}</td>
                                  )}{" "}
                                  {visible.col3 && <td>{d?.user_name}</td>}{" "}
                                  {visible.col4 && <td>{d?.studio_name}</td>}{" "}
                                  {visible.col5 && (
                                    <td>₹ {Math.round(d?.amount)}</td>
                                  )}
                                  {visible.col6 && (
                                    <td>₹ {Math.round(d?.vender_amount)}</td>
                                  )}{" "}
                                  {visible.col7 && (
                                    <td>₹ {Math.round(d?.admin_amount)}</td>
                                  )}{" "}
                                  {visible.col8 && (
                                    <td>{d?.platform_percentage} %</td>
                                  )}{" "}
                                  {visible.col10 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.payment_status}
                                          disabled={!isAllow?.includes(51)}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.payment_status
                                            ? "Active"
                                            : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {visible.col9 && (
                                    <td>{formatDate(d?.createdAt)}</td>
                                  )}{" "}
                                  {/* {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.base_price_discount}
                                          disabled
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.base_price_discount
                                            ? "Active"
                                            : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}{" "}
                                  {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.free_booking_in_hours}
                                          disabled
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.free_booking_in_hours
                                            ? "Active"
                                            : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}{" "}
                                  {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.reschedule}
                                          disabled
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.reschedule ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}{" "}
                                  {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.premium_studios}
                                          disabled
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.premium_studios
                                            ? "Active"
                                            : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}{" "}
                                  {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.discount_on_platform_fee}
                                          disabled
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.discount_on_platform_fee
                                            ? "Active"
                                            : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}{" "}
                                  {visible.col5 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.customer_support}
                                          disabled
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.customer_support
                                            ? "Active"
                                            : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {visible.col4 && (
                                    <td>
                                      <div className="d-flex">
                                        {isAllow?.includes(55) ? (
                                          <EditButton
                                            handleShow1={handleShow1}
                                            id={d?.id}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                  )} */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />

      <ModelBulkUpload
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
      <Toaster position="top-right" />

      <OffcanvasCon show={showoff} handleClose={() => setShowoff(false)} />
    </>
  );
};

export default Tables;
