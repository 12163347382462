import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";
import { faAffiliatetheme } from "@fortawesome/free-brands-svg-icons";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen, isAllow } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      // setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="sidebar">
        <div className="desktop-view-sidebar">
          <div className="top_section">
            <img
              className="logo "
              src={
                process.env.PUBLIC_URL +
                "/FlickNShoot-_-LOGO-for-dashboard-white-logo.png"
              }
              // src={process.env.PUBLIC_URL + "/netpurtilogo.png"}
              // src={process.env.PUBLIC_URL + "/Mansa_Nutri_Logo.png"}
            />
            {/* <h3>Flick And Shoot</h3>/ */}
            {/* <h1>Net Purti</h1> */}
          </div>

          <ul className="list-unstyled ps-0">
            {/* ***************************************************Dashboard*************************************************************** */}

            <Accordion
              // defaultActiveKey="0"
              className=""
              data-bs-toggle="collapse"
              data-bs-target="#home-collapse"
              aria-expanded="false"
            >
              <Accordion.Item
                eventKey="0"
                className=""
                onClick={() => handleClick("0")}
              >
                <Accordion.Header>
                  <div
                    className="Accordion_Header_icon-holder
"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-house"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    className="sidebar_txt"
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                    onClick={() => setSidebarOpen(true)}
                  >
                    Dashboard
                  </p>

                  {/* </button> */}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ul
                    className={`accordion_list list-unstyled fw-normal pb-1 small ${
                      isSidebarOpen ? "arrowshow" : "arrowHide"
                    }`}
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                  >
                    <Link
                      to="/advanceDashboard"
                      className="text-decoration-none"
                    >
                      <li className={isActive("/advanceDashboard")}>
                        <p key={2} className="insideDropdown_list" id={"2"}>
                          Advance Dashboard
                        </p>
                      </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              {isAllow?.includes(138) ||
              isAllow?.includes(142) ||
              isAllow?.includes(140) ? (
                <Accordion.Item
                  eventKey="22"
                  className=""
                  onClick={() => handleClick("22")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-house"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      onClick={() => setSidebarOpen(true)}
                    >
                      Vendor Management
                    </p>

                    {/* </button> */}
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <ul
                      className={`accordion_list list-unstyled fw-normal pb-1 small ${
                        isSidebarOpen ? "arrowshow" : "arrowHide"
                      }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(138) ? (
                        <Link
                          to="/employee/employee-details"
                          className="text-decoration-none"
                        >
                          <li
                            className={isActive("/employee/employee-details")}
                          >
                            <p key={2} className="insideDropdown_list" id={"2"}>
                              Vendor Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(139) ? (
                        <Link
                          to="/employee/employee-requests"
                          className="text-decoration-none"
                        >
                          <li
                            className={isActive("/employee/employee-requests")}
                          >
                            <p key={2} className="insideDropdown_list" id={"2"}>
                              Vendor Request
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(141) ? (
                        <Link
                          to="/employee/employee-rejected"
                          className="text-decoration-none"
                        >
                          <li
                            className={isActive("/employee/employee-rejected")}
                          >
                            <p key={2} className="insideDropdown_list" id={"2"}>
                              Vendor Rejected
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* *********************************************************Dashboard Ends*************************************************************** */}
              {isAllow?.includes(1) ||
              isAllow?.includes(2) ||
              isAllow?.includes(3) ||
              isAllow?.includes(4) ||
              isAllow?.includes(5) ||
              isAllow?.includes(6) ||
              isAllow?.includes(7) ||
              isAllow?.includes(8) ? (
                <Accordion.Item
                  eventKey="16"
                  className=""
                  onClick={() => handleClick("16")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-people-roof"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Role Management
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(1) ? (
                        <Link
                          to="/employee/role"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/role")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Roles
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* {isAllow?.includes(5) ||
                      isAllow?.includes(6) ||
                      isAllow?.includes(7) ||
                      isAllow?.includes(8) ? (
                      <Link
                        to="/employee/employee-details"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/employee/employee-details")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Employee Details
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )} */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(132) ||
              isAllow?.includes(134) ||
              isAllow?.includes(136) ? (
                <Accordion.Item
                  eventKey="23"
                  className=""
                  onClick={() => handleClick("22")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-house"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                      onClick={() => setSidebarOpen(true)}
                    >
                      Bookings
                    </p>

                    {/* </button> */}
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <ul
                      className={`accordion_list list-unstyled fw-normal pb-1 small ${
                        isSidebarOpen ? "arrowshow" : "arrowHide"
                      }`}
                      style={{
                        display: isSidebarOpen ? "block" : "none",
                      }}
                    >
                      {isAllow?.includes(132) ? (
                        <Link to="/booking" className="text-decoration-none">
                          <li className={isActive("/booking")}>
                            <p key={2} className="insideDropdown_list" id={"2"}>
                              Bookings
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(134) ? (
                        <Link
                          to="/booking-request"
                          className="text-decoration-none"
                        >
                          <li className={isActive("/booking-request")}>
                            <p key={2} className="insideDropdown_list" id={"3"}>
                              Booking Request
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(136) ? (
                        <Link
                          to="/booking-rejected"
                          className="text-decoration-none"
                        >
                          <li className={isActive("/booking-rejected")}>
                            <p key={2} className="insideDropdown_list" id={"2"}>
                              Rejected Bookings
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* {isAllow?.includes(5) ||
              isAllow?.includes(6) ||
              isAllow?.includes(7) ||
              isAllow?.includes(8) ? (
                <Accordion.Item
                  eventKey="4"
                  className=""
                  onClick={() => handleClick("4")}
                >
                  <Accordion.Header>
                    <div className="Accordion_Header_icon-holder
">
                      <FontAwesomeIcon
                        icon="fa fa-store"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Sellers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      <Link
                        to="/employee/employee-requests"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/employee/employee-requests"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Seller Request
                          </p>
                        </li>
                      </Link>

                    

                      <Link
                        to="/seller-detail"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/seller-detail")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Sellers Details
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/employee/employee-rejected"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/employee/employee-rejected"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Rejected Requests
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )} */}
              {/* ************************************************************   Content Start ************************************************************* */}
              {isAllow?.includes(45) ||
              isAllow?.includes(46) ||
              isAllow?.includes(47) ||
              isAllow?.includes(48) ||
              isAllow?.includes(49) ||
              isAllow?.includes(50) ||
              isAllow?.includes(51) ||
              isAllow?.includes(52) ||
              isAllow?.includes(53) ||
              isAllow?.includes(54) ||
              isAllow?.includes(55) ||
              isAllow?.includes(56) ||
              isAllow?.includes(57) ? (
                <Accordion.Item
                  eventKey="20"
                  className=""
                  onClick={() => handleClick("20")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Settings
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(45) ? (
                        <Link
                          to="/settings/customization/faqs"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/faqs"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              FAQ
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(49) ? (
                        <Link
                          to="/settings/add-on"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/add-on")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Add On
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(53) ? (
                        <Link
                          to="/settings/additional-support"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/additional-support"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Additional Support
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(57) ? (
                        <Link
                          to="/settings/booking-cancel"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/booking-cancel"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Booking Cancel Reason
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Settings End ************************************************************* */}
              {/* ************************************************************  Categories ************************************************************* */}
              {isAllow?.includes(29) ||
              isAllow?.includes(30) ||
              isAllow?.includes(31) ||
              isAllow?.includes(32) ||
              isAllow?.includes(33) ||
              isAllow?.includes(34) ||
              isAllow?.includes(35) ||
              isAllow?.includes(36) ? (
                <Accordion.Item
                  eventKey="2"
                  className=""
                  onClick={() => handleClick("2")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon icon="fa fa-bullseye" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Categories
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      <Link
                        to="/settings/category"
                        className=" text-decoration-none  "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/category")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Category
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/settings/sub-category"
                        className=" text-decoration-none  "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/sub-category")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Sub Category
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Categories End ************************************************************* */}
              {/* ************************************************************  Home Settings  ************************************************************* */}
              {isAllow?.includes(37) ||
              isAllow?.includes(38) ||
              isAllow?.includes(39) ||
              isAllow?.includes(40) ||
              isAllow?.includes(41) ||
              isAllow?.includes(42) ||
              isAllow?.includes(43) ||
              isAllow?.includes(44) ? (
                <Accordion.Item
                  eventKey="9"
                  className=""
                  onClick={() => handleClick("9")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-house-laptop"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Home Settings
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(37) ? (
                        <Link
                          to="/settings/customization/home-banner"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/home-banner"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Home Banner
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(41) ? (
                        <Link
                          to="/settings/customization/motivated-by"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/motivated-by"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Motivated By
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(126) ? (
                        <Link
                          to="/settings/specials"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/specials")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Specials
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Home Settings End ************************************************************* */}
              {/* ************************************************************  Customers ************************************************************* */}
              {isAllow?.includes(114) || isAllow?.includes(118) ? (
                <Accordion.Item
                  eventKey="18"
                  className=""
                  onClick={() => handleClick("18")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa fa-users"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      {isAllow?.includes(114) ? (
                        <Link
                          to="/employee/contact-us"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/contact-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Contact Us
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(118) ? (
                        <Link
                          to="/employee/inquiry"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/inquiry")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Inquiry
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(5) ? (
                        <Link
                          to="/employee/customer-details"
                          className="text-decoration-none"
                        >
                          <li
                            className={isActive("/employee/customer-details")}
                          >
                            <p key={2} className="insideDropdown_list" id={"2"}>
                              Customer Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(141) ? (
                        <Link
                          to="/user-subscription"
                          className="text-decoration-none"
                        >
                          <li className={isActive("/user-subscription")}>
                            <p key={2} className="insideDropdown_list" id={"3"}>
                              User Subscription
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Customers End ************************************************************* */}
              {/* ************************************************************  Customization ************************************************************* */}
              {isAllow?.includes(73) ||
              isAllow?.includes(74) ||
              isAllow?.includes(75) ||
              isAllow?.includes(76) ? (
                <Accordion.Item
                  eventKey="5"
                  className=""
                  onClick={() => handleClick("5")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-sliders"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customization
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      {isAllow?.includes(73) ? (
                        <Link
                          to="/settings/customization/app-setup"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/customization/app-setup"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              App Setup
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Customization End ************************************************************* */}
              {/* ************************************************************  Location ************************************************************* */}
              {isAllow?.includes(9) ||
              isAllow?.includes(10) ||
              isAllow?.includes(11) ||
              isAllow?.includes(12) ||
              isAllow?.includes(13) ||
              isAllow?.includes(14) ||
              isAllow?.includes(15) ||
              isAllow?.includes(16) ||
              isAllow?.includes(17) ||
              isAllow?.includes(18) ||
              isAllow?.includes(19) ||
              isAllow?.includes(20) ||
              isAllow?.includes(21) ||
              isAllow?.includes(22) ||
              isAllow?.includes(23) ||
              isAllow?.includes(24) ? (
                <Accordion.Item
                  eventKey="8"
                  className=""
                  onClick={() => handleClick("8")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-map-location-dot"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Location
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(9) ? (
                        <Link
                          to="/settings/location/country"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/location/country"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Country
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(13) ? (
                        <Link
                          to="/settings/location/state"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/location/state"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              State
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(17) ? (
                        <Link
                          to="/settings/location/city"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/location/city")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              City
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(21) ? (
                        <Link
                          to="/settings/location/pincode"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                    "/settings/location/pincode"
                                  )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Pincode
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Location End ************************************************************* */}
              {/* ************************************************************  Why  Section  ************************************************************* */}
              {isAllow?.includes(61) ||
              isAllow?.includes(62) ||
              isAllow?.includes(63) ||
              isAllow?.includes(64) ? (
                <Accordion.Item
                  eventKey="14"
                  className=""
                  onClick={() => handleClick("14")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        // icon="fa-regular fa-circle-question"
                        icon="fa-solid fa-circle-question"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Why Section
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(61) ? (
                        <Link
                          to="/why/why-fns"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/why/why-fns")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Why FNS
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Why End ************************************************************* */}
              {/* ************************************************************   About Us Start ************************************************************* */}
              {isAllow?.includes(65) ||
              isAllow?.includes(66) ||
              isAllow?.includes(67) ||
              isAllow?.includes(68) ||
              isAllow?.includes(69) ||
              isAllow?.includes(70) ||
              isAllow?.includes(71) ||
              isAllow?.includes(72) ||
              isAllow?.includes(77) ||
              isAllow?.includes(78) ||
              isAllow?.includes(79) ||
              isAllow?.includes(80) ||
              isAllow?.includes(81) ||
              isAllow?.includes(82) ||
              isAllow?.includes(83) ||
              isAllow?.includes(84) ||
              isAllow?.includes(85) ||
              isAllow?.includes(86) ||
              isAllow?.includes(87) ||
              isAllow?.includes(88) ||
              isAllow?.includes(89) ||
              isAllow?.includes(90) ? (
                <Accordion.Item
                  eventKey="17"
                  className=""
                  onClick={() => handleClick("17")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      About Us
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      {isAllow?.includes(65) ? (
                        <Link
                          to="/about-us/about-banner"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/about-banner")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              About Banner
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(69) ? (
                        <Link
                          to="/about-us/our-mission"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/our-mission")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Our Vision Mission
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(77) ? (
                        <Link
                          to="/about-us/social-media"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/social-media")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Social Media
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow?.includes(83) ? (
                        <Link
                          to="/about-us/about-us-main"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/about-us-main")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              About Us
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(87) ? (
                        <Link
                          to="/about-us/our-team"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/our-team")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Our Team
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   About Us End ************************************************************* */}
              {/* ************************************************************   Content Start ************************************************************* */}
              {isAllow?.includes(91) ||
              isAllow?.includes(92) ||
              isAllow?.includes(93) ||
              isAllow?.includes(94) ||
              isAllow?.includes(95) ||
              isAllow?.includes(96) ||
              isAllow?.includes(97) ||
              isAllow?.includes(98) ? (
                <Accordion.Item
                  eventKey="19"
                  className=""
                  onClick={() => handleClick("19")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Policy Content
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(91) ? (
                        <Link
                          to="/content/privacy"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/privacy")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Privacy Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(95) ? (
                        <Link
                          to="/content/terms"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/terms")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Terms & Conditions
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(99) ||
              isAllow?.includes(100) ||
              isAllow?.includes(101) ||
              isAllow?.includes(102) ||
              isAllow?.includes(122) ||
              isAllow?.includes(123) ||
              isAllow?.includes(130) ||
              isAllow?.includes(131) ? (
                <Accordion.Item
                  eventKey="21"
                  className=""
                  onClick={() => handleClick("21")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Studio
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(99) ? (
                        <Link
                          to="/studio"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/studio")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Studio
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(122) ? (
                        <Link
                          to="/studio/request"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/studio/request")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Studio Request
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {isAllow?.includes(130) ? (
                        <Link
                          to="/studio/rejected"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/studio/rejected")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Studio Rejected
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(142) ? (
                <Accordion.Item
                  eventKey="24"
                  className=""
                  onClick={() => handleClick("24")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Subscription
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(142) ? (
                        <Link
                          to="/subscription"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/subscription")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Subscription
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {isAllow?.includes(143) ? (
                <Accordion.Item
                  eventKey="25"
                  className=""
                  onClick={() => handleClick("25")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Wallet History
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      {isAllow?.includes(143) ? (
                        <Link
                          to="/wallet-history"
                          className=" text-decoration-none  "
                          // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/wallet-history")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Wallet History
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Content End ************************************************************* */}
            </Accordion>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
