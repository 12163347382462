import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import ModalSave from "../common/ModelSave";
import Select from "react-select";
import { CancelButton, SaveButton } from "../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageValidation } from "../../utils/common";
import { ValidationImage } from "../../utils/api";
library.add(fas);

const EditOffCanvance = (props) => {
  const {
    postData,
    getData,
    Select2Data,
    IMG_URL,
    getDimension,
    deleteData,
    isAllow,
    usertype,
  } = useContext(Context);

  console.log(usertype, "usertype");

  const [cities, setCities] = useState([]);
  const [addOn, setAddOn] = useState([]);

  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childCategories, setchildCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setPercentages] = useState([]);
  const [attributes, setAttribute] = useState([]);
  const [subAttributes, setSubAttribute] = useState([]);

  const id = props.show;
  const [role, setRole] = useState([]);
  const [data, setData] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [open24Hours, setOpen24Hours] = useState("");
  console.log(open24Hours, "open24Hours");

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  useEffect(() => {
    console.log("user?.id == ", user?.id);
    console.log("i am here ", user);
    if (user?.id === 1) {
      setIsDisabled(true);
    }
  }, [user]);

  console.log("isDisabled ", isDisabled);

  // const [showModal, setShowModal] = useState({
  //   code: 0,
  //   message: "",
  // });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    setError,
  } = useForm();

  const images = watch("images");
  // console.log("imagesFile:", imagesFile);
  const firstHStartTime = watch("first_h_start_time");
  const firstHEndTime = watch("first_h_end_time");
  const secondHStartTime = watch("second_h_start_time");
  const secondHEndTime = watch("second_h_end_time");
  const fullHStartTime = watch("full_d_start_time");
  const fullHEndTime = watch("full_d_end_time");
  const fulldDayCharges = watch("full_day_charges");
  const halfDayCharges = watch("half_day_charges");

  console.log(fulldDayCharges, "fulldDayCharges");
  console.log(halfDayCharges, "halfDayCharges");

  const calculateHoursDifference = (startTime, endTime) => {
    if (!startTime || !endTime) return 0;
    // Parse time strings into Date objects
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startDate = new Date(0, 0, 0, startHour, startMinute);
    const endDate = new Date(0, 0, 0, endHour, endMinute);

    // Calculate difference in hours
    const diffMilliseconds = endDate - startDate;
    const hoursDiff = diffMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours

    return hoursDiff;
  };

  const descriptionValue = watch("description");
  useEffect(() => {
    register("description", {
      required: "Description is required.",
    });
  }, [register]);

  const featuresAndAminitiesValue = watch("features_and_aminities");
  useEffect(() => {
    register("features_and_aminities", {
      required: "Features And Aminities is required.",
    });
  }, [register]);

  const termsAndConditionValue = watch("terms_and_condition");
  useEffect(() => {
    register("terms_and_condition", {
      required: "Terms And Condition is required.",
    });
  }, [register]);

  const validateTimesOnChange = () => {
    let hasErrors = false; // Flag to track if any errors are set

    // Validate First Half End Time
    if (firstHStartTime && firstHEndTime && firstHStartTime > firstHEndTime) {
      setError("first_h_end_time", {
        type: "manual",
        message: "First Half End Time must be after First Half Start Time",
      });
      hasErrors = true;
    } else {
      clearErrors("first_h_end_time");
    }

    // Validate Second Half Start Time
    if (firstHEndTime && secondHStartTime && firstHEndTime > secondHStartTime) {
      setError("second_h_start_time", {
        type: "manual",
        message: "Second Half Start Time must be after First Half End Time",
      });
      hasErrors = true;
    } else {
      clearErrors("second_h_start_time");
    }

    // Validate Second Half End Time
    if (
      secondHStartTime &&
      secondHEndTime &&
      secondHStartTime > secondHEndTime
    ) {
      setError("second_h_end_time", {
        type: "manual",
        message: "Second Half End Time must be after Second Half Start Time",
      });
      hasErrors = true;
    } else {
      clearErrors("second_h_end_time");
    }

    if (fulldDayCharges && halfDayCharges && fulldDayCharges < halfDayCharges) {
      setError("full_day_charges", {
        type: "manual",
        message: "Full day Charges Greter Than half day Charges",
      });
      hasErrors = true;
    } else {
      clearErrors("full_day_charges");
    }

    // Validate Full Day End Time
    if (fullHStartTime && fullHEndTime && fullHStartTime > fullHEndTime) {
      setError("full_d_end_time", {
        type: "manual",
        message: "Full day End Time must be after Full day Start Time",
      });
      hasErrors = true;
    } else {
      clearErrors("full_d_end_time");
    }

    // Calculate hours between firstHStartTime and secondHEndTime if no previous errors
    if (
      !hasErrors &&
      firstHStartTime &&
      secondHEndTime &&
      fullHStartTime &&
      fullHEndTime
    ) {
      const hoursBetweenHalfTimes = calculateHoursDifference(
        firstHStartTime,
        secondHEndTime
      );
      const hoursBetweenFullTimes = calculateHoursDifference(
        fullHStartTime,
        fullHEndTime
      );
      console.log(hoursBetweenHalfTimes, "hoursBetweenHalfTimes");
      console.log(hoursBetweenFullTimes, "hoursBetweenFullTimes");

      if (hoursBetweenHalfTimes > hoursBetweenFullTimes) {
        setError("full_d_end_time", {
          type: "manual",
          message:
            "Total time between the halves exceeds the full day duration",
        });
        hasErrors = true;
      } else {
        clearErrors("full_d_end_time");
      }
    }
  };

  // Trigger validation when any of the time fields change
  useEffect(() => {
    validateTimesOnChange(); // Run the validation when the times change
  }, [
    firstHStartTime,
    firstHEndTime,
    secondHStartTime,
    secondHEndTime,
    fullHStartTime,
    fullHEndTime,
    fulldDayCharges,
    halfDayCharges,
  ]);

  const {
    fields: projectFields,
    append: appendProject,
    remove: removeProject,
  } = useFieldArray({
    control,
    name: "project",
  });

  const {
    fields: addOnFields,
    append: appendAddOn,
    remove: removeAddOn,
  } = useFieldArray({
    control,
    name: "add_on",
  });

  const [quantityError, setquantityError] = useState("");

  useEffect(() => {
    register("project", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setquantityError(isValid ? "" : "At least one quantity is required");
        return isValid;
      },
    });
  }, [register]);

  console.log(getValues("full_d_start_time"), "full_d_start_time");
  console.log(getValues("full_d_end_time"), "full_d_end_time");

  const startTime = getValues("full_d_start_time"); // Example start time
  const endTime = getValues("full_d_end_time"); // Example end time

  function calculateTimeDifferenceInHours(startTime, endTime) {
    // Parse time strings into Date objects with a fixed date
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);

    // Calculate the difference in milliseconds
    let diffInMs = end - start;

    // Convert milliseconds to hours
    const diffInHours = diffInMs / (1000 * 60 * 60);

    // Round the difference to the nearest hour
    const roundedDiffInHours = Math.round(diffInHours);

    return roundedDiffInHours;
  }

  const roundedTimeDifferenceInHours = calculateTimeDifferenceInHours(
    startTime,
    endTime
  );
  console.log(roundedTimeDifferenceInHours, "Rounded time difference in hours");

  console.log(open24Hours, "open24Hours");
  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("name", data?.name);
      DataToSend.append("city_id", data?.city_id?.value);
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("pincode_id", data?.pincode_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      DataToSend.append("description", data?.description);
      DataToSend.append("address", data?.address);
      DataToSend.append("features_and_aminities", data?.features_and_aminities);
      DataToSend.append("terms_and_condition", data?.terms_and_condition);
      DataToSend.append("studio_crew_capacity", data?.studio_crew_capacity);
      if (data?.open_24_hours) {
        DataToSend.append("open_24_hours", data?.open_24_hours);
      }

      DataToSend.append("half_day_charges", data?.half_day_charges);
      if (data?.first_h_start_time) {
        DataToSend.append("first_h_start_time", data?.first_h_start_time);
      }
      if (data?.first_h_end_time) {
        DataToSend.append("first_h_end_time", data?.first_h_end_time);
      }
      if (data?.second_h_start_time) {
        DataToSend.append("second_h_start_time", data?.second_h_start_time);
      }
      if (data?.second_h_end_time) {
        DataToSend.append("second_h_end_time", data?.second_h_end_time);
      }

      DataToSend.append("half_day_hours", data?.half_day_hours);

      DataToSend.append("full_day_charges", data?.full_day_charges);
      if (data?.full_d_start_time) {
        DataToSend.append("full_d_start_time", data?.full_d_start_time);
      }
      if (data?.full_d_end_time) {
        DataToSend.append("full_d_end_time", data?.full_d_end_time);
      }

      DataToSend.append("full_day_hours", roundedTimeDifferenceInHours);

      DataToSend.append("hourly_basis_charges", data?.hourly_basis_charges);
      DataToSend.append("minimum_hours", data?.minimum_hours);
      DataToSend.append("lat", data?.lat);
      DataToSend.append("long", data?.long);
      if (images && images.length > 0) {
        images.forEach((image, index) => {
          DataToSend.append(`images${index}`, image);
        });
      }
      console.log(projectFields, "projectFields projectFields projectFields");
      // projectFields.forEach((variant, index) => {
      //   const fileInput = data.project[index]?.image?.[0];
      //   if (fileInput) {
      //     DataToSend.append(`projectimages${index}`, fileInput);
      //   }
      // });
      console.log(data.project, "data.project data.project data.project");
      // Append project
      const project = [];
      data.project.forEach((variant, index) => {
        const projectData = {
          id: variant.id,
          name: variant.name,
          month: variant.month,
          year: variant.year,
        };
        if (variant.image && variant.image.length > 0) {
          console.log(variant.image, "variant.image image");
          if (variant.image[0] instanceof File) {
            DataToSend.append(`projectimages${index}`, variant.image[0]);
          }
        }

        project.push(projectData);
      });

      const add_on = [];
      data.add_on.forEach((variant) => {
        const addOn = {
          rate: variant.rate,
          add_onn_id: variant.add_onn_id?.value,
        };

        add_on.push(addOn);
      });
      console.log(DataToSend, "DataToSend DataToSend DataToSend DataToSend");
      DataToSend.append("images", JSON.stringify(images));
      DataToSend.append("add_on", JSON.stringify(add_on));
      DataToSend.append("project", JSON.stringify(project));
      const response = await postData(`/studio/${id}`, DataToSend);
      console.log("response", response);
      console.log("data to send", DataToSend);
      if (response?.success) {
        await props.setShowModal({
          code: response.code,
          message: response.message,
        });
        props.handleClose();
      } else {
        await props.setShowModal({
          code: response.code,
          message: response.message,
        });
      }
      setTimeout(() => {
        props.setShowModal({ code: 0, message: response.message });
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllCities = async () => {
    {
      const response = await getData("/allcity");

      if (response?.success) {
        setCities(await Select2Data(response?.data, "city_id"));
      }
    }
  };

  const [pincode, setPincode] = useState([]);
  const GetAllPincode = async (id) => {
    const response = await getData(`/allpincodes/${id}`);
    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  const GetAllAddOn = async () => {
    const response = await getData("/all-add-on");
    if (response?.success) {
      setAddOn(await Select2Data(response?.data, "add_onn_id"));
    }
  };

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");

    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  useEffect(() => {
    GetAllCities();
    GetAllAddOn();
    GetAllCategory();
    GetAllSubCategory();
  }, []);
  const GetEditData = async () => {
    const response = await getData(`/studio/${id}`);
    setData(response?.data);
    reset(response?.data);
    setOpen24Hours(response?.data?.open_24_hours == 1 ? true : false);
  };

  useEffect(() => {
    GetEditData();
  }, []);
  console.log(getValues(), "getvalue allllllll kkkkkkkkkkk");
  const deleteImage = async (index) => {
    const updatedimages = [...images];
    updatedimages.splice(index, 1);
    setValue("images", updatedimages);
  };

  const [imagePreviews, setImagePreviews] = useState([]);
  const [imagePreviewsvarient, setImagePreviewsVarient] = useState([]);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const newImagePreviews = [];
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImagePreviews.push(reader.result);
          if (newImagePreviews.length === files.length) {
            setImagePreviews(newImagePreviews);
            setValue("images", files);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handlevarientImageChange = (e, index) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewsVarient((prevPreviews) => {
          const newPreviews = [...prevPreviews];
          newPreviews[index] = reader.result;
          return newPreviews;
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const deleteStudioImage = async (imageId) => {
    try {
      const response = await deleteData(`/studio/studio-image/${imageId}`);
      if (response.success) {
        console.log("Image deleted successfully");
      } else {
        console.error("Failed to delete image");
      }
      GetEditData();
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "-" || event.key === "e") {
      event.preventDefault(); // Prevent negative sign and scientific notation
    }
  };

  const hours = watch("full_day_hours");

  useEffect(() => {
    if (startTime && hours) {
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const endHour = (startHour + Number(hours)) % 24; // Ensure it wraps around 24-hour clock
      const endTime = `${endHour.toString().padStart(2, "0")}:${startMinute
        .toString()
        .padStart(2, "0")}`;

      setValue("full_d_end_time", endTime); // Set the calculated end time
    }
  }, [startTime, hours]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
          className="prodcuttttt-offcanvas"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Edit Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5  rounded card">
              <Card.Body>
                {usertype == "vendor" ? (
                  <Card.Title> Edit Studio</Card.Title>
                ) : (
                  <Card.Title> Studio Details</Card.Title>
                )}

                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                    // className="stateclass"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Category</Form.Label>
                            <Controller
                              name="category_id"
                              {...register("category_id", {
                                required: "Select Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={categories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    GetAllSubCategory(selectedOption.value);
                                    setValue("category_id", selectedOption);
                                    setValue("sub_category_id", "");
                                    setValue("child_sub_category_id", "");
                                  }}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.category_id && (
                              <span className="text-danger">
                                {errors.category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Sub Category</Form.Label>
                            <Controller
                              name="sub_category_id"
                              {...register("sub_category_id", {
                                required: "Select Sub Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.sub_category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={subCategories}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    // GetAllChildSubCategory(selectedOption.value);
                                    setValue("sub_category_id", selectedOption);
                                    setValue("child_sub_category_id", "");
                                  }}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.sub_category_id && (
                              <span className="text-danger">
                                {errors.sub_category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Studio Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="name"
                                  placeholder="Studio Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Studio Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Address</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="address"
                                  placeholder="Address .."
                                  className={classNames("", {
                                    "is-invalid": errors?.address,
                                  })}
                                  {...register("address", {
                                    required: "Address is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.address && (
                                <span className="text-danger">
                                  {errors.address.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>City</Form.Label>
                            <Controller
                              name="city_id" // name of the field
                              {...register("city_id", {
                                required: "Select Child Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.city_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={cities}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    GetAllPincode(selectedOption.value);
                                    setValue("city_id", selectedOption);
                                    setValue("pincode_id", "");
                                  }}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.city_id && (
                              <span className="text-danger">
                                {errors.city_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Pincode</Form.Label>
                            <Controller
                              name="pincode_id" // name of the field
                              {...register("pincode_id", {
                                required: "Select pincode",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.pincode_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={pincode}
                                  isDisabled={isDisabled}
                                />
                              )}
                            />
                            {errors.pincode_id && (
                              <span className="text-danger">
                                {errors.pincode_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Studio Crew Capacity</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="number"
                                  min={1}
                                  name="studio_crew_capacity"
                                  placeholder="studio crew capacity .."
                                  className={classNames("", {
                                    "is-invalid": errors?.studio_crew_capacity,
                                  })}
                                  {...register("studio_crew_capacity", {
                                    required:
                                      "Studio crew capacity is required",
                                    validate: (value) =>
                                      value > 0 ||
                                      "Studio crew capacity must be greater than 0",
                                  })}
                                  onKeyPress={handleKeyPress}
                                />
                              </InputGroup>
                              {errors.studio_crew_capacity && (
                                <span className="text-danger">
                                  {errors.studio_crew_capacity.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Lat</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="number"
                                  name="lat"
                                  placeholder="Lat .."
                                  className={classNames("", {
                                    "is-invalid": errors?.lat,
                                  })}
                                  {...register("lat", {
                                    required: "Lat is required",
                                  })}
                                  onKeyPress={handleKeyPress}
                                />
                              </InputGroup>
                              {errors.lat && (
                                <span className="text-danger">
                                  {errors.lat.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Long</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="number"
                                  name="long"
                                  placeholder="Long .."
                                  className={classNames("", {
                                    "is-invalid": errors?.long,
                                  })}
                                  {...register("long", {
                                    required: "Long is required",
                                  })}
                                  onKeyPress={handleKeyPress}
                                />
                              </InputGroup>
                              {errors.long && (
                                <span className="text-danger">
                                  {errors.long.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      {!isDisabled && (
                        <Form.Group>
                          <Form.Label>Upload Images</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                          <div className="image-previews">
                            {imagePreviews.map((preview, index) => (
                              <div key={index} className="image-preview">
                                <img src={preview} alt={`preview-${index}`} />
                                <Button
                                  variant="danger"
                                  onClick={() => deleteImage(index)}
                                  className="btn btn-danger mb-3"
                                >
                                  Delete
                                </Button>
                              </div>
                            ))}
                          </div>
                        </Form.Group>
                      )}

                      <Col lg={6}>
                        <div className="main-form-section edit-studio-table mt-3">
                          <Form.Label>images</Form.Label>

                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Images</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.images?.map((image, imageIndex) => (
                                <tr key={imageIndex}>
                                  <td>
                                    <img
                                      src={IMG_URL + image.image}
                                      alt={`Image ${imageIndex + 1}`}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </td>
                                  <td style={{ width: "50px" }}>
                                    {!isDisabled && (
                                      <Button
                                        variant="danger"
                                        onClick={() =>
                                          deleteStudioImage(image?.id)
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "0.375rem 0.75rem",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={["fas", "trash"]}
                                        />
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                description
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="description" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{
                                    required: "Description is required",
                                  }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={descriptionValue || ""}
                                      onBlur={(newContent) => {
                                        setValue("description", newContent);
                                        trigger("description");
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.description && (
                                <span className="text-danger">
                                  {errors.description.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Features And Aminities</Card.Title>
                      <hr />

                      <Col lg={12}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Features And Aminities
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="features_and_aminities" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{
                                    required:
                                      "  Features And Aminities is required",
                                  }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={featuresAndAminitiesValue || ""}
                                      onBlur={(newContent) => {
                                        setValue(
                                          "features_and_aminities",
                                          newContent
                                        );
                                        trigger("features_and_aminities");
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.features_and_aminities && (
                                <span className="text-danger">
                                  {errors.features_and_aminities.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Terms And Condition</Card.Title>
                      <hr />

                      <Col lg={12}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Terms And Condition
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="terms_and_condition" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{
                                    required:
                                      "  Terms And Condition is required",
                                  }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={termsAndConditionValue || ""}
                                      onBlur={(newContent) => {
                                        setValue(
                                          "terms_and_condition",
                                          newContent
                                        );
                                        trigger("terms_and_condition");
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.terms_and_condition && (
                                <span className="text-danger">
                                  {errors.terms_and_condition.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      {/* <div className="main-form-section mt-5"></div>
                      <Card.Title>Charges</Card.Title>
                      <hr />

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Half Day Charges</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="half_day_charges"
                                  placeholder="Half Day Charges"
                                  min="0"
                                  className={classNames("", {
                                    "is-invalid": errors?.half_day_charges,
                                  })}
                                  {...register("half_day_charges", {
                                    required: "Half Day Charges is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.half_day_charges && (
                                <span className="text-danger">
                                  {errors.half_day_charges.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Full Day Charges</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="full_day_charges"
                                  placeholder="Full Day Charges"
                                  min="0"
                                  className={classNames("", {
                                    "is-invalid": errors?.full_day_charges,
                                  })}
                                  {...register("full_day_charges", {
                                    required: "Full Day Charges is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.full_day_charges && (
                                <span className="text-danger">
                                  {errors.full_day_charges.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Hourly Basis Charges</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="hourly_basis_charges"
                                  placeholder="Hourly Basis Charges"
                                  min="0"
                                  className={classNames("", {
                                    "is-invalid": errors?.hourly_basis_charges,
                                  })}
                                  {...register("hourly_basis_charges", {
                                    required:
                                      "Hourly Basis Charges is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.hourly_basis_charges && (
                                <span className="text-danger">
                                  {errors.hourly_basis_charges.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col> */}

                      <div>
                        <div className="main-form-section mt-5"></div>
                        <Card.Title>Charges</Card.Title>
                        <div className="col-md-12 mb-3">
                          <Form.Group controlId="includeHalfDayCharges">
                            <Form.Check
                              type="checkbox"
                              label="Open 24 Hours"
                              name="open_24_hours"
                              onClick={(e) => {
                                setOpen24Hours(e.target.checked); // Assuming setOpen24Hours is a function that handles the change
                              }}
                              {...register("open_24_hours")}
                            />
                          </Form.Group>
                        </div>

                        <hr />

                        <Col md={12}>
                          <div className="main-form-section mt-3">
                            <Row className="justify-content-center">
                              <div className="col-md-3 border-right-holder">
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <Form.Label>Half Day Charges</Form.Label>
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          disabled={isDisabled}
                                          type="number"
                                          name="half_day_charges"
                                          placeholder="Half Day Charges"
                                          min="0"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.half_day_charges,
                                          })}
                                          {...register("half_day_charges", {
                                            required:
                                              "Half Day Charges is required",
                                            validate: (value) =>
                                              value > 0 ||
                                              "Half Day Charges must be greater than 0",
                                          })}
                                          onKeyPress={handleKeyPress}
                                        />
                                      </InputGroup>
                                      {errors.half_day_charges && (
                                        <span className="text-danger">
                                          {errors.half_day_charges.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-9">
                                {open24Hours == 1 || open24Hours ? (
                                  ""
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-4 mb-3">
                                        <Form.Label>
                                          1st Half Day Time
                                        </Form.Label>
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <Form.Label className="mb-3">
                                          First Half Start Time
                                        </Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              disabled={isDisabled}
                                              type="time"
                                              name="first_h_start_time"
                                              placeholder="First Half Start Time"
                                              min="0"
                                              className={classNames("", {
                                                "is-invalid":
                                                  errors?.first_h_start_time,
                                              })}
                                              {...register(
                                                "first_h_start_time",
                                                {
                                                  required:
                                                    "First Half Start Time is required",
                                                }
                                              )}
                                            />
                                          </InputGroup>
                                          {errors.first_h_start_time && (
                                            <span className="text-danger">
                                              {
                                                errors.first_h_start_time
                                                  .message
                                              }
                                            </span>
                                          )}
                                        </Form.Group>
                                      </div>

                                      <div className="col-md-4 mb-3">
                                        <Form.Label className="mb-3">
                                          First Half End Time
                                        </Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              disabled={isDisabled}
                                              type="time"
                                              name="first_h_end_time"
                                              min={firstHStartTime}
                                              placeholder="First Half End Time"
                                              // min="0"
                                              className={classNames("", {
                                                "is-invalid":
                                                  errors?.first_h_end_time,
                                              })}
                                              {...register("first_h_end_time", {
                                                required:
                                                  "First Half End Time is required",
                                              })}
                                            />
                                          </InputGroup>
                                          {errors.first_h_end_time && (
                                            <span className="text-danger">
                                              {errors.first_h_end_time.message}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-4 mb-3">
                                        <Form.Label>
                                          2nd Half Day Time
                                        </Form.Label>
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <Form.Label className="mb-3">
                                          Second Half Start Time
                                        </Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              disabled={isDisabled}
                                              type="time"
                                              name="second_h_start_time"
                                              placeholder="Second Half Start Time"
                                              min="0"
                                              className={classNames("", {
                                                "is-invalid":
                                                  errors?.second_h_start_time,
                                              })}
                                              {...register(
                                                "second_h_start_time",
                                                {
                                                  required:
                                                    "Second Half Start Time is required",
                                                }
                                              )}
                                            />
                                          </InputGroup>
                                          {errors.second_h_start_time && (
                                            <span className="text-danger">
                                              {
                                                errors.second_h_start_time
                                                  .message
                                              }
                                            </span>
                                          )}
                                        </Form.Group>
                                      </div>

                                      <div className="col-md-4 mb-3">
                                        <Form.Label className="mb-3">
                                          Second Half End Time
                                        </Form.Label>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              disabled={isDisabled}
                                              type="time"
                                              name="second_h_end_time"
                                              placeholder="Second Half End Time"
                                              min="0"
                                              className={classNames("", {
                                                "is-invalid":
                                                  errors?.second_h_end_time,
                                              })}
                                              {...register(
                                                "second_h_end_time",
                                                {
                                                  required:
                                                    "Second Half End Time is required",
                                                }
                                              )}
                                            />
                                          </InputGroup>
                                          {errors.second_h_end_time && (
                                            <span className="text-danger">
                                              {errors.second_h_end_time.message}
                                            </span>
                                          )}
                                        </Form.Group>
                                      </div>
                                    </div>
                                    {errors.timeValidation && (
                                      <div className="col-md-12 text-danger">
                                        {errors.timeValidation.message}
                                      </div>
                                    )}
                                  </>
                                )}

                                <div className="row">
                                  <div className="col-md-4 mb-3">
                                    <Form.Label>Half Day Hours</Form.Label>
                                  </div>
                                  <div className="col-md-4 mb-3">
                                    <Form.Label className="mb-3">
                                      Half Day Hours
                                    </Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          disabled={isDisabled}
                                          type="number"
                                          name="half_day_hours"
                                          placeholder="Half Day Hours"
                                          // min="0"
                                          min={secondHStartTime}
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.half_day_hours,
                                          })}
                                          {...register("half_day_hours", {
                                            required:
                                              "Half Day Hours is required",
                                            validate: (value) =>
                                              value > 0 ||
                                              "Half Day Hours must be greater than 0",
                                          })}
                                          onKeyPress={handleKeyPress}
                                        />
                                      </InputGroup>
                                      {errors.half_day_hours && (
                                        <span className="text-danger">
                                          {errors.half_day_hours.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="main-form-section mt-3">
                            <Row className="justify-content-center">
                              <div className="col-md-3 border-right-holder">
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <Form.Label>Full Day Charges</Form.Label>
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          disabled={isDisabled}
                                          type="number"
                                          name="full_day_charges"
                                          min="0"
                                          placeholder="Full Day Charges"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.full_day_charges,
                                          })}
                                          {...register("full_day_charges", {
                                            required:
                                              "Full Day Charges is required",
                                            validate: (value) =>
                                              value > 0 ||
                                              "Full Day Charges must be greater than 0",
                                          })}
                                          onKeyPress={handleKeyPress}
                                        />
                                      </InputGroup>
                                      {errors.full_day_charges && (
                                        <span className="text-danger">
                                          {errors.full_day_charges.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-9">
                                {open24Hours ? (
                                  ""
                                ) : (
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <Form.Label>Time</Form.Label>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <Form.Label className="mb-3">
                                        Start Time
                                      </Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            disabled={isDisabled}
                                            type="time"
                                            name="full_d_start_time"
                                            min="0"
                                            placeholder="Start Time"
                                            className={classNames("", {
                                              "is-invalid":
                                                errors?.full_d_start_time,
                                            })}
                                            {...register("full_d_start_time", {
                                              required:
                                                "Start Time is required",
                                            })}
                                          />
                                        </InputGroup>
                                        {errors.full_d_start_time && (
                                          <span className="text-danger">
                                            {errors.full_d_start_time.message}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </div>

                                    <div className="col-md-4 mb-3">
                                      <Form.Label className="mb-3">
                                        Hours
                                      </Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            disabled={isDisabled}
                                            type="number"
                                            name="full_day_hours"
                                            min="0"
                                            placeholder="Hours"
                                            className={classNames("", {
                                              "is-invalid":
                                                errors?.full_day_hours,
                                            })}
                                            {...register("full_day_hours", {
                                              required: "Hours is required",
                                              min: {
                                                value: 1,
                                                message:
                                                  "Hours cannot be negative",
                                              },
                                            })}
                                          />
                                        </InputGroup>
                                        {errors.full_day_hours && (
                                          <span className="text-danger">
                                            {errors.full_day_hours.message}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </div>

                                    <div className="col-md-4 mb-3">
                                      <Form.Label className="mb-3">
                                        End Time
                                      </Form.Label>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            disabled
                                            type="time"
                                            name="full_d_end_time"
                                            placeholder="End Time"
                                            className={classNames("", {
                                              "is-invalid":
                                                errors?.full_d_end_time,
                                            })}
                                            {...register("full_d_end_time", {
                                              required: "End Time is required",
                                            })}
                                          />
                                        </InputGroup>
                                        {errors.full_d_end_time && (
                                          <span className="text-danger">
                                            {errors.full_d_end_time.message}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Row>
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="main-form-section mt-3">
                            <Row className="justify-content-center">
                              <div className="col-md-3  border-right-holder">
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <Form.Label>
                                      Hourly Basis Charges
                                    </Form.Label>
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          disabled={isDisabled}
                                          type="number"
                                          name="hourly_basis_charges"
                                          min="0"
                                          placeholder="Hourly Basis Charges"
                                          className={classNames("", {
                                            "is-invalid":
                                              errors?.hourly_basis_charges,
                                          })}
                                          {...register("hourly_basis_charges", {
                                            required:
                                              " Hourly Basis Charges is required",
                                            validate: (value) =>
                                              value > 0 ||
                                              "Hourly Basis Charges must be greater than 0",
                                          })}
                                          onKeyPress={handleKeyPress}
                                        />
                                      </InputGroup>
                                      {errors.hourly_basis_charges && (
                                        <span className="text-danger">
                                          {errors.hourly_basis_charges.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-md-4 mb-3">
                                    <Form.Label>Time</Form.Label>
                                  </div>
                                  <div className="col-md-4 mb-3">
                                    <Form.Label className="mb-3">
                                      Minimum Hours
                                    </Form.Label>
                                    <Form.Group>
                                      <InputGroup>
                                        <Form.Control
                                          disabled={isDisabled}
                                          type="number"
                                          name="minimum_hours"
                                          placeholder="Minimum Hours"
                                          min="0"
                                          className={classNames("", {
                                            "is-invalid": errors?.minimum_hours,
                                          })}
                                          {...register("minimum_hours", {
                                            required:
                                              "Minimum Hours is required",
                                            validate: (value) => {
                                              if (value <= 0) {
                                                return "Minimum Hours must be greater than 0";
                                              }
                                              if (value >= 10) {
                                                return "Should be less than 10";
                                              }
                                            },
                                          })}
                                        />
                                      </InputGroup>
                                      {errors.minimum_hours && (
                                        <span className="text-danger">
                                          {errors.minimum_hours.message}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </Col>
                      </div>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>
                        Add On
                        {quantityError && (
                          <div className="text-danger">{quantityError}</div>
                        )}
                      </Card.Title>
                      <hr />

                      {addOnFields.map((variant, index) => (
                        <div
                          key={variant.id}
                          className="main-form-section mt-3"
                        >
                          <Row>
                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="row justify-content-center mb-2">
                                  <Form.Label>Add on</Form.Label>
                                  <Controller
                                    name={`add_on.${index}.add_onn_id`} // name of the field
                                    {...register(`add_on.${index}.add_onn_id`, {
                                      required: "Select Add On",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        styles={{
                                          control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors?.add_on?.[index]
                                              ?.add_onn_id
                                              ? "red"
                                              : baseStyles,
                                          }),
                                        }}
                                        {...field}
                                        options={addOn}
                                        isDisabled={isDisabled}
                                      />
                                    )}
                                  />
                                  {errors?.add_on?.[index]?.add_onn_id && (
                                    <span className="text-danger">
                                      {
                                        errors?.add_on?.[index]?.add_onn_id
                                          .message
                                      }
                                    </span>
                                  )}
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Rate</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="number"
                                        name={`add_on.${index}.rate`}
                                        placeholder="Month"
                                        min="0"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.add_on?.[index]?.rate,
                                        })}
                                        {...register(`add_on.${index}.rate`, {
                                          required: "Month is required",
                                          validate: (value) =>
                                            value > 0 ||
                                            "Month must be greater than 0",
                                        })}
                                        onKeyPress={handleKeyPress}
                                      />
                                    </InputGroup>
                                    {errors?.add_on?.[index]?.rate && (
                                      <span className="text-danger">
                                        {errors?.add_on?.[index]?.rate.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            {usertype == "vendor" && (
                              <Col lg={3}>
                                <button
                                  className="btn btn-primary mb-3"
                                  type="button"
                                  onClick={() => removeAddOn(index)}
                                >
                                  Remove Add On
                                </button>
                              </Col>
                            )}
                          </Row>
                        </div>
                      ))}

                      <div className="main-form-section mt-3"></div>
                      <hr />
                      {usertype == "vendor" && (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn btn-primary mt-3"
                            onClick={() => appendAddOn({})}
                          >
                            + Add Add on
                          </button>
                        </div>
                      )}

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>
                        Projects
                        {quantityError && (
                          <div className="text-danger">{quantityError}</div>
                        )}
                      </Card.Title>
                      <hr />

                      {projectFields.map((variant, index) => (
                        <div
                          key={variant.id}
                          className="main-form-section mt-3"
                        >
                          <Row>
                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="text"
                                        name={`project.${index}.name`}
                                        placeholder="Name"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.project?.[index]?.name,
                                        })}
                                        {...register(`project.${index}.name`, {
                                          required: "Name is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.project?.[index]?.name && (
                                      <span className="text-danger">
                                        {errors?.project?.[index]?.name.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Month</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Select
                                        disabled={isDisabled}
                                        name={`project.${index}.month`}
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.project?.[index]?.month,
                                        })}
                                        {...register(`project.${index}.month`, {
                                          required: "Month is required",
                                        })}
                                      >
                                        <option value="">Select Month</option>
                                        <option value="Jan">Jan</option>
                                        <option value="Feb">Feb</option>
                                        <option value="March">March</option>
                                        <option value="April">April</option>
                                        <option value="May">May</option>
                                        <option value="June">June</option>
                                        <option value="July">July</option>
                                        <option value="Aug">Aug</option>
                                        <option value="Sep">Sep</option>
                                        <option value="Oct">Oct</option>
                                        <option value="Nov">Nov</option>
                                        <option value="Dec">Dec</option>
                                      </Form.Select>
                                    </InputGroup>
                                    {errors?.project?.[index]?.month && (
                                      <span className="text-danger">
                                        {
                                          errors?.project?.[index]?.month
                                            .message
                                        }
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Year</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="number"
                                        name={`project.${index}.year`}
                                        min="0"
                                        max={currentYear} // Set the max attribute to the current year
                                        placeholder="Year"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.project?.[index]?.year,
                                        })}
                                        {...register(`project.${index}.year`, {
                                          required: "Year is required",
                                          validate: (value) =>
                                            (value > 0 &&
                                              value <= currentYear) ||
                                            `must be  ${currentYear} Or Less Than This`,
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.project?.[index]?.year && (
                                      <span className="text-danger">
                                        {errors?.project?.[index]?.year.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Image</Form.Label>
                                {!isDisabled && (
                                  <InputGroup>
                                    <Form.Control
                                      type="file"
                                      {...register(`project.${index}.image`, {
                                        validate: async (value) => {
                                          if (typeof value !== "string") {
                                            // const fileTypes = [
                                            //   "jpg",
                                            //   "png",
                                            //   "jpeg",
                                            // ];
                                            const fileType =
                                              value[0].name?.split(".")[1];

                                            // if (!fileTypes.includes(fileType)) {
                                            //   return `please upload a valid file format. (${fileTypes})`;
                                            // }

                                            const sizes = await getDimension(
                                              value[0]
                                            );
                                            if (
                                              sizes.width !== 352 &&
                                              sizes.height !== 236
                                            ) {
                                              return "Image width and height must be 352 px and 236 px";
                                            }

                                            // const fileSize = Math.round(
                                            //   value[0].size / 1024
                                            // );
                                            // if (fileSize > 500) {
                                            //   return "file size must be lower than 500kb";
                                            // }
                                          }
                                        },
                                      })}
                                      accept="image/*"
                                      onChange={(e) =>
                                        handlevarientImageChange(e, index)
                                      }
                                    />
                                  </InputGroup>
                                )}
                                {errors?.project?.[index]?.image && (
                                  <span className="text-danger">
                                    {errors?.project?.[index]?.image.message}
                                  </span>
                                )}
                                {/* Displaying preview if available */}
                                {(imagePreviewsvarient[index] ||
                                  (variant.image &&
                                    !imagePreviewsvarient[index])) && (
                                  <div className="mt-2">
                                    <img
                                      src={
                                        imagePreviewsvarient[index] ||
                                        IMG_URL + variant.image
                                      }
                                      alt="Project Preview"
                                      style={{ maxWidth: "100px" }}
                                    />
                                  </div>
                                )}
                              </Form.Group>
                            </Col>

                            {/* <Col md={6}>
                            <div className="main-form-section mt-3">
                              <Form.Group>
                                <div className="text-center">
                                  <Form.Label>Image</Form.Label>
                                </div>
                                <InputGroup>
                                  <Form.Control
                                    type="file"
                                    name={`project.${index}.image`}
                                    placeholder="Image"
                                    className={classNames("", {
                                      "is-invalid": errors?.project?.[index]?.image,
                                    })}
                                    {...register(`project.${index}.image`, {
                                      // required: "Image is required",
                                    })}
                                    accept="image/*"
                                    id={`project.${index}.image`}
                                  // onChange={(e) => handleImageprojectChange(e, index)}
                                  />
                                </InputGroup>
                                {errors.project?.[index]?.image && (
                                  <span className="text-danger">
                                    {errors.project?.[index]?.image.message}
                                  </span>
                                )}
                                {variant.image && (
                                  <div className="mt-2">
                                    <img
                                      src={IMG_URL + variant.image}
                                      alt="Project Preview"
                                      style={{ maxWidth: "100px" }}
                                    />
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                          </Col> */}

                            {/* <Col md={6}>
                            <div className="main-form-section mt-3">
                              <Form.Group>
                                <div className="text-center">
                                  <Form.Label>Image</Form.Label>
                                </div>
                                <InputGroup>
                                  <Form.Control
                                    type="file"
                                    name={`project.${index}.image`}
                                    placeholder="Image"
                                    className={classNames("", {
                                      "is-invalid":
                                        errors?.project?.[index]?.image,
                                    })}
                                    {...register(`project.${index}.image`, {
                                      required: "Image is required",
                                    })}
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImageChange(e, index)
                                    }
                                  />
                                </InputGroup>
                                {errors.project?.[index]?.image && (
                                  <span className="text-danger">
                                    {errors.project?.[index]?.image.message}
                                  </span>
                                )}
                              </Form.Group>
                            </div>
                          </Col> */}

                            {/* <Col md={6}>
                            <div className="main-form-section mt-3">
                              {imagePreview && (
                                <div className="mt-2">
                                  <img
                                    src={imagePreview}
                                    alt="Category Preview"
                                    style={{ maxWidth: "100px" }}
                                  />
                                </div>
                              )}
                            </div>
                          </Col> */}

                            {usertype == "vendor" && (
                              <Col lg={3}>
                                <button
                                  className="btn btn-danger mt-3"
                                  type="button"
                                  onClick={() => removeProject(index)}
                                >
                                  Remove Project
                                </button>
                              </Col>
                            )}
                          </Row>
                        </div>
                      ))}

                      <div className="main-form-section mt-3"></div>
                      <hr />
                      {usertype == "vendor" && (
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn btn-primary mt-3"
                            onClick={() => appendProject({})}
                          >
                            + Add Project
                          </button>
                        </div>
                      )}

                      {usertype == "vendor" && (
                        <Row className="mt-5 pb-3">
                          <div className="d-flex justify-content-center">
                            <Link>
                              <CancelButton
                                name={"cancel"}
                                handleClose={props.handleClose}
                              />
                            </Link>

                            <Button
                              name={"save"}
                              onClick={handleSubmit(onSubmit)}
                              type="button"
                              className="save-btnnnnnn"
                            >
                              <FontAwesomeIcon
                                icon="fa-solid fa-floppy-disk"
                                className="me-2"
                              />
                              save
                            </Button>
                          </div>
                        </Row>
                      )}
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default EditOffCanvance;
