import React, { useContext, useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../common/ModelSave";
import { CancelButton, SaveButton } from "../common/Button";
import {
  Row,
  Col,
  Form,
  Modal,
  Container,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../utils/context";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageValidation } from "../../utils/common";
import { ValidationImage } from "../../utils/api";

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, getDimension } = useContext(Context);

  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [addOn, setAddOn] = useState([]);

  const [units, setUnits] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [taxPercentages, setTaxPercentages] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [subAttributes, setSubAttributes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);
  useEffect(() => {
    if (parseInt(user?.id) === 1) {
      setIsDisabled(false);
    }
  }, [user]);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const images = watch("images");

  const {
    fields: projectFields,
    append: appendProject,
    remove: removeProject,
  } = useFieldArray({
    control,
    name: "project",
  });

  const {
    fields: addOnFields,
    append: appendAddOn,
    remove: removeAddOn,
  } = useFieldArray({
    control,
    name: "add_on",
  });

  const [quantityError, setQuantityError] = useState("");

  useEffect(() => {
    register("project", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setQuantityError(isValid ? "" : "At least one Project is required");
        return isValid;
      },
    });
  }, [register]);

  const [addOnError, setAddOnError] = useState("");

  useEffect(() => {
    register("add_on", {
      validate: (value) => {
        const isValid = value && value.length > 0;
        setAddOnError(isValid ? "" : "At least one Add on is required");
        return isValid;
      },
    });
  }, [register]);
  const [imagePreview, setImagePreview] = useState(null);
  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("name", data?.name);
      DataToSend.append("city_id", data?.city_id?.value);
      DataToSend.append("category_id", data?.category_id?.value);
      DataToSend.append("pincode_id", data?.pincode_id?.value);
      DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      DataToSend.append("description", data?.description);
      DataToSend.append("address", data?.address);
      DataToSend.append("features_and_aminities", data?.features_and_aminities);
      DataToSend.append("terms_and_condition", data?.terms_and_condition);
      DataToSend.append("half_day_charges", data?.half_day_charges);
      DataToSend.append("full_day_charges", data?.full_day_charges);
      DataToSend.append("hourly_basis_charges", data?.hourly_basis_charges);

      // Append images
      if (images && images.length > 0) {
        images.forEach((image, index) => {
          DataToSend.append(`images${index}`, image);
        });
      }

      // Append project
      const project = [];
      data.project.forEach((variant, index) => {
        const projectData = {
          name: variant.name,
          // image: variant.image[0],
          month: variant.month,
          year: variant.year,
        };
        if (variant.image && variant.image.length > 0) {
          DataToSend.append(`projectimages${index}`, variant.image[0]);
        }
        project.push(projectData);
      });

      // data.project.forEach((variant, index) => {
      //   if (variant.image && variant.image.length > 0) {
      //     DataToSend.append(`project_images_${index}`, variant.image[0]);
      //   }
      // });
      // Append add_on
      const add_on = [];
      data.add_on.forEach((variant) => {
        const addOn = {
          rate: variant.rate,
          add_onn_id: variant.add_onn_id?.value,
        };

        add_on.push(addOn);
      });
      DataToSend.append("images", JSON.stringify(images));
      DataToSend.append("add_on", JSON.stringify(add_on));
      DataToSend.append("project", JSON.stringify(project));

      const response = await postData(`/studio`, DataToSend);

      if (response?.success) {
        setShowModal({
          code: response?.code,
          message: response?.message,
        });
      } else {
        setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal({ code: 0, message: "" });
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllCities = async () => {
    const response = await getData(`/allcity`);
    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };
  const GetAllPincode = async (id) => {
    const response = await getData(`/allpincodes/${id}`);
    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");
    if (response?.success) {
      setCategory(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);
    if (response?.success) {
      setSubCategory(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllAddOn = async () => {
    const response = await getData("/all-add-on");
    if (response?.success) {
      setAddOn(await Select2Data(response?.data, "add_onn_id"));
    }
  };

  useEffect(() => {
    GetAllCities();
    GetAllAddOn();
    GetAllCategory();
    GetAllSubCategory();
  }, []);

  const deleteImage = async (index) => {
    const updatedimages = [...images];
    updatedimages.splice(index, 1);
    setValue("images", updatedimages);
  };

  const [imagePreviews, setImagePreviews] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const newImagePreviews = [];
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newImagePreviews.push(reader.result);
          if (newImagePreviews.length === files.length) {
            setImagePreviews(newImagePreviews);
            setValue("images", files);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  // const [imageFile, setImageFile] = useState(null);

  // const handleImageprojectChange = (e, index) => {
  //   const file = e.target.files[0];
  //   setValue(`project.${index}.image`, file); // Update form state
  //   setImageFile(file); // Update local state
  // };
  const handleImageprojectChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      // Update form state if needed
      setValue(`project.${index}.image`, file); // Update form state with the file
    }
  };

  return (
    <>
      <div className="prodcuttttt">
        <Offcanvas
          show={props.show}
          style={{ width: "80%" }}
          placement={"end"}
          onHide={props.handleClose}
          className="prodcuttttt-offcanvas"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card className="shadow-lg p-3 mb-5  rounded card card">
              <Card.Body>
                <Card.Title>Add Studio</Card.Title>
                <hr />
                <Container>
                  <Form
                    // onSubmit={() => handleSubmit(onSubmit)}
                    role="form"
                    // className="stateclass"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Category</Form.Label>
                            <Controller
                              isDisabled={isDisabled}
                              name="category_id"
                              {...register("category_id", {
                                required: "Select Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={category}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    GetAllSubCategory(selectedOption.value);
                                    setValue("category_id", selectedOption);
                                    setValue("sub_category_id", "");
                                    setValue("child_sub_category_id", "");
                                  }}
                                />
                              )}
                            />
                            {errors.category_id && (
                              <span className="text-danger">
                                {errors.category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Sub Category</Form.Label>
                            <Controller
                              isDisabled={isDisabled}
                              name="sub_category_id"
                              {...register("sub_category_id", {
                                required: "Select Sub Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.sub_category_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={subCategory}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    // GetAllChildSubCategory(
                                    //   selectedOption.value
                                    // );
                                    setValue("sub_category_id", selectedOption);
                                    setValue("child_sub_category_id", "");
                                  }}
                                />
                              )}
                            />
                            {errors.sub_category_id && (
                              <span className="text-danger">
                                {errors.sub_category_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Name</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="name"
                                  placeholder="Name .."
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Name is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Address</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="address"
                                  placeholder="Address .."
                                  className={classNames("", {
                                    "is-invalid": errors?.address,
                                  })}
                                  {...register("address", {
                                    required: "Address is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.address && (
                                <span className="text-danger">
                                  {errors.address.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>City</Form.Label>
                            <Controller
                              isDisabled={isDisabled}
                              name="city_id" // name of the field
                              {...register("city_id", {
                                required: "Select Child Category",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.city_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={cities}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                    GetAllPincode(selectedOption.value);
                                    setValue("city_id", selectedOption);
                                    setValue("pincode_id", "");
                                  }}
                                />
                              )}
                            />
                            {errors.city_id && (
                              <span className="text-danger">
                                {errors.city_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Pincode</Form.Label>
                            <Controller
                              isDisabled={isDisabled}
                              name="pincode_id" // name of the field
                              {...register("pincode_id", {
                                required: "Select pincode",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.pincode_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={pincode}
                                />
                              )}
                            />
                            {errors.pincode_id && (
                              <span className="text-danger">
                                {errors.pincode_id.message}
                              </span>
                            )}
                          </Row>
                        </div>
                      </Col>
                      {!isDisabled && (
                        <Form.Group>
                          <Form.Label>Upload Images</Form.Label>
                          <Form.Control
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleImageChange}
                          />
                          <div className="image-previews">
                            {imagePreviews.map((preview, index) => (
                              <div key={index} className="image-preview">
                                <img
                                  src={preview}
                                  alt={`preview-${index}`}
                                  style={{ width: "100px", height: "100px" }}
                                />
                                {/* <Button
                                variant="danger"
                                onClick={() => deleteImage(index)}
                              >
                                Delete
                              </Button> */}
                              </div>
                            ))}
                          </div>
                        </Form.Group>
                      )}
                      <Col lg={12}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Description
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="description" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{
                                    required: "Description is required",
                                  }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field?.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid": !!errors.description,
                                      })}
                                      placeholder="Description"
                                      config={{
                                        readonly: isDisabled, // Set the editor to read-only mode based on isDisabled value
                                        toolbar: !isDisabled, // Show the toolbar if isDisabled is false
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.description && (
                                <span className="text-danger">
                                  {errors.description.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Features And Aminities</Card.Title>
                      <hr />

                      <Col lg={12}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Features And Aminities
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="features_and_aminities" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{
                                    required:
                                      "Features And Aminities is required",
                                  }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field?.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid":
                                          !!errors.features_and_aminities,
                                      })}
                                      placeholder="Features And Aminities"
                                      config={{
                                        readonly: isDisabled, // Set the editor to read-only mode based on isDisabled value
                                        toolbar: !isDisabled, // Show the toolbar if isDisabled is false
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.features_and_aminities && (
                                <span className="text-danger">
                                  {errors.features_and_aminities.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Terms And Condition</Card.Title>
                      <hr />

                      <Col lg={12}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-start">
                            {" "}
                            {/* Align to left */}
                            {/* Description */}
                            <Col sm={12}>
                              <Form.Label className="text-center">
                                Terms And Condition
                              </Form.Label>
                              <Form.Group>
                                <Controller
                                  name="terms_and_condition" // Provide the field name
                                  control={control} // Pass the control object from useForm()
                                  rules={{
                                    required: "Terms And Condition is required",
                                  }} // Validation rules
                                  render={({ field }) => (
                                    <JoditEditor
                                      value={field?.value}
                                      onChange={(newContent) =>
                                        field.onChange(newContent)
                                      }
                                      onBlur={field.onBlur}
                                      className={classNames("", {
                                        "is-invalid":
                                          !!errors.terms_and_condition,
                                      })}
                                      placeholder="Terms And Condition"
                                      config={{
                                        readonly: isDisabled, // Set the editor to read-only mode based on isDisabled value
                                        toolbar: !isDisabled, // Show the toolbar if isDisabled is false
                                      }}
                                    />
                                  )}
                                />
                              </Form.Group>
                              {errors.terms_and_condition && (
                                <span className="text-danger">
                                  {errors.terms_and_condition.message}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>Charges</Card.Title>
                      <hr />

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Half Day Charges</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="half_day_charges"
                                  placeholder="Half Day Charges"
                                  className={classNames("", {
                                    "is-invalid": errors?.half_day_charges,
                                  })}
                                  {...register("half_day_charges", {
                                    required: "Half Day Charges is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.half_day_charges && (
                                <span className="text-danger">
                                  {errors.half_day_charges.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Full Day Charges</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="full_day_charges"
                                  placeholder="Full Day Charges"
                                  className={classNames("", {
                                    "is-invalid": errors?.full_day_charges,
                                  })}
                                  {...register("full_day_charges", {
                                    required: "Full Day Charges is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.full_day_charges && (
                                <span className="text-danger">
                                  {errors.full_day_charges.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="main-form-section mt-3">
                          <Row className="justify-content-center">
                            <Form.Label>Hourly Basis Charges</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  disabled={isDisabled}
                                  type="text"
                                  name="hourly_basis_charges"
                                  placeholder="Hourly Basis Charges"
                                  className={classNames("", {
                                    "is-invalid": errors?.hourly_basis_charges,
                                  })}
                                  {...register("hourly_basis_charges", {
                                    required:
                                      "Hourly Basis Charges is required",
                                  })}
                                />
                              </InputGroup>
                              {errors.hourly_basis_charges && (
                                <span className="text-danger">
                                  {errors.hourly_basis_charges.message}
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>
                        Add On
                        {addOnError && (
                          <div className="text-danger">{addOnError}</div>
                        )}
                      </Card.Title>
                      <hr />

                      {addOnFields.map((variant, index) => (
                        <div
                          key={variant.id}
                          className="main-form-section mt-3"
                        >
                          <Row>
                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="row justify-content-center mb-2">
                                  <Form.Label>Add on</Form.Label>
                                  <Controller
                                    isDisabled={isDisabled}
                                    name={`add_on.${index}.add_onn_id`} // name of the field
                                    {...register(`add_on.${index}.add_onn_id`, {
                                      required: "Select Add On",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        styles={{
                                          control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors?.add_on?.[index]
                                              ?.add_onn_id
                                              ? "red"
                                              : baseStyles,
                                          }),
                                        }}
                                        {...field}
                                        options={addOn}
                                      />
                                    )}
                                  />
                                  {errors?.add_on?.[index]?.add_onn_id && (
                                    <span className="text-danger">
                                      {
                                        errors?.add_on?.[index]?.add_onn_id
                                          .message
                                      }
                                    </span>
                                  )}
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Rate</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="number"
                                        name={`add_on.${index}.rate`}
                                        placeholder="Rate"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.add_on?.[index]?.rate,
                                        })}
                                        {...register(`add_on.${index}.rate`, {
                                          required: "Rate is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.add_on?.[index]?.rate && (
                                      <span className="text-danger">
                                        {errors?.add_on?.[index]?.rate.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col lg={3}>
                              <button
                                className="mt-3 add-varient"
                                type="button"
                                onClick={() => removeAddOn(index)}
                              >
                                Remove Add On
                              </button>
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <div className="main-form-section mt-3"></div>
                      <hr />
                      <div className="text-center">
                        <button
                          type="button"
                          className="add-varient"
                          onClick={() => appendAddOn({})}
                        >
                          + Add Add on
                        </button>
                      </div>

                      <div className="main-form-section mt-5"></div>
                      <Card.Title>
                        Projects
                        {quantityError && (
                          <div className="text-danger">{quantityError}</div>
                        )}
                      </Card.Title>
                      <hr />

                      {projectFields.map((variant, index) => (
                        <div
                          key={variant.id}
                          className="main-form-section mt-3"
                        >
                          <Row>
                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="text"
                                        name={`project.${index}.name`}
                                        placeholder="Name"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.project?.[index]?.name,
                                        })}
                                        {...register(`project.${index}.name`, {
                                          required: "Name is required",
                                        })}
                                      />
                                    </InputGroup>
                                    {errors?.project?.[index]?.name && (
                                      <span className="text-danger">
                                        {errors?.project?.[index]?.name.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Month</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="number"
                                        name={`project.${index}.month`}
                                        placeholder="Month"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.project?.[index]?.month,
                                        })}
                                        {...register(`project.${index}.month`, {
                                          required: "Month is required",
                                          min: {
                                            value: 1,
                                            message:
                                              "Month cannot be negative or zero",
                                          },
                                          validate: (value) =>
                                            value.toString().length <= 2 ||
                                            "Year must be 4 digits or less",
                                        })}
                                        onInput={(e) => {
                                          if (e.target.value.length > 2) {
                                            e.target.value =
                                              e.target.value.slice(0, 2);
                                          }
                                        }}
                                      />
                                    </InputGroup>
                                    {errors?.project?.[index]?.month && (
                                      <span className="text-danger">
                                        {
                                          errors?.project?.[index]?.month
                                            .message
                                        }
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Year</Form.Label>
                                  <Form.Group>
                                    <InputGroup>
                                      <Form.Control
                                        disabled={isDisabled}
                                        type="number"
                                        name={`project.${index}.year`}
                                        placeholder="Year"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.project?.[index]?.year,
                                        })}
                                        {...register(`project.${index}.year`, {
                                          required: "Year is required",
                                          min: {
                                            value: 1,
                                            message:
                                              "Year cannot be negative or zero",
                                          },
                                          validate: (value) =>
                                            value.toString().length <= 4 ||
                                            "Year must be 4 digits or less",
                                        })}
                                        onInput={(e) => {
                                          if (e.target.value.length > 4) {
                                            e.target.value =
                                              e.target.value.slice(0, 4);
                                          }
                                        }}
                                      />
                                    </InputGroup>
                                    {errors?.project?.[index]?.year && (
                                      <span className="text-danger">
                                        {errors?.project?.[index]?.year.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Row>
                              </div>
                            </Col>
                            {!isDisabled && (
                              <Col md={6}>
                                <div className="main-form-section mt-3">
                                  <Form.Group>
                                    <div className="text-center">
                                      <Form.Label>Image</Form.Label>
                                    </div>
                                    <InputGroup>
                                      <Form.Control
                                        type="file"
                                        name={`project.${index}.image`}
                                        placeholder="Image"
                                        className={classNames("", {
                                          "is-invalid":
                                            errors?.project?.[index]?.image,
                                        })}
                                        {...register(`project.${index}.image`, {
                                          required: "Image is required",
                                        })}
                                        accept="image/*"
                                        // onChange={(e) => handleImageprojectChange(e, index)}
                                      />
                                    </InputGroup>
                                    {errors.project?.[index]?.image && (
                                      <span className="text-danger">
                                        {errors.project?.[index]?.image.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </div>
                              </Col>
                            )}
                            {/* <Col className="main-form-section mt-3">
                              <div className="main-form-section mt-3">
                                <Row className="justify-content-center">
                                  <Form.Label>Image Preview</Form.Label>
                                  {imageFile && (
                                    <div className="image-preview-container mt-3">
                                      <img
                                        src={URL.createObjectURL(imageFile)}
                                        alt="Preview"
                                        className="image-preview"
                                        style={{ width: "100px", height: "100px" }}
                                      />
                                    </div>
                                  )}
                                </Row>
                              </div>
                            </Col> */}

                            <Col lg={3}>
                              <button
                                className="mt-3 add-varient"
                                type="button"
                                onClick={() => removeProject(index)}
                              >
                                Remove Project
                              </button>
                            </Col>
                          </Row>
                        </div>
                      ))}

                      <div className="main-form-section mt-3"></div>
                      <hr />
                      <div className="text-center">
                        <button
                          type="button"
                          className="add-varient"
                          onClick={() => appendProject({})}
                        >
                          + Add Project
                        </button>
                      </div>

                      <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Link>
                            <CancelButton
                              name={"cancel"}
                              handleClose={props.handleClose}
                            />
                          </Link>

                          <Button
                            name={"save"}
                            onClick={handleSubmit(onSubmit)}
                            type="button"
                            className="save-btnnnnnn me-2"
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-floppy-disk"
                              className="me-2"
                            />
                            save
                          </Button>

                          {/* <Button
                            name={"next"}
                            type="button"
                            className="save-btnnnnnn me-2"
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-floppy-disk"
                              className="me-2"
                            />
                            Next
                          </Button> */}
                        </div>
                      </Row>

                      <hr />
                      {/* <MultiVarientMain /> */}
                    </Row>
                  </Form>
                </Container>
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>

        <ModalSave
          message={showModal.message}
          showErrorModal={showModal.code ? true : false}
        />
      </div>
    </>
  );
};

export default AddOffCanvance;
