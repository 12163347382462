import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import plus from "../../Components/assets/icons/a1.png";
import colunms from "../../Components/assets/icons/LINES.png";
import pen from "../../Components/assets/icons/pen.png";
import eye from "../../Components/assets/icons/eye.png";
import basket from "../../Components/assets/icons/basket.png";
import search1 from "../../Components/assets/icons/search.png";
import top from "../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../assets/icons/circle.png";
import rigth from "../assets/icons/rigth.png";
import save from "../assets/icons/save.png";
import cancel from "../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../utils/context";
import { FaEye } from "react-icons/fa";
import { formatDate } from "../../utils/common";
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../utils/api";

library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    IMG_URL,
    Per_Page_Dropdown,
    Select2Data,
    usertype,
  } = useContext(Context);

  console.log(usertype, "usertypeeeeeeeeeeeeeee");

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [resets, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchByUser, setSearchByUser] = useState(null);
  const [vender, setvender] = useState("");
  const [studioName, setStudioName] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setSearchByUser(id || null); // Set to null if id is undefined or null
    getDataAll();
  }, [id]);

  useEffect(() => {
    getDataAll();
  }, [searchByUser]);

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm();

  // console.log(getValues("type").value, "Type");

  const type = [
    {
      value: "Hourly",
      label: "Hourly",
    },
    {
      value: "Daily",
      label: "Daily",
    },
  ];

  const [changeStatus, setChangeStatus] = useState();
  const [changeStatusTopStudio, setChangeStatusTopStudio] = useState();
  const [changeStatusPremiumStudio, setChangeStatusPremiumStudio] = useState();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [fromDaily, setFromDaily] = useState("");
  const [toDaily, setToDaily] = useState("");

  const getDataAll = async () => {
    const queryString = `/booking-request?page=${currentPage}&per_page=${perPage}&term=${search}&vender=${
      vender || ""
    }&studioName=${studioName || ""}&from=${from ? formatDate(from) : ""} &to=${
      to ? formatDate(to) : ""
    }&fromdaily=${fromDaily ? formatDate(fromDaily) : ""} &todaily=${
      toDaily ? formatDate(toDaily) : ""
    }`;
    const response = await getData(queryString);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    console.log("response", response);
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatusTopStudio,
    changeStatusPremiumStudio,
    perPage,
    resets,
    show,
    show1,
    search,
    studioName,
    vender,
    from,
    to,
    fromDaily,
    toDaily,
    changeStatus,
  ]);

  const ChangeStatus = async (id, selectedOption) => {
    try {
      const response = await postData(`/booking-request/request/${id}`, {
        stage: selectedOption.value,
      });

      if (response.success) {
        alert("Status changed successfully!");
      } else {
        alert(response?.message || "Failed to change status.");
      }

      setChangeStatus(response);
    } catch (error) {
      console.error("Error changing status:", error);
      alert("This Time Slot Already Accepted");
    }
  };

  const ChangeStatusTopStudio = async (id) => {
    const response = await editStatusData(`/studio/top-studio-status/${id}`);
    setChangeStatusTopStudio(response);
  };

  const ChangeStatusPremiumStudio = async (id) => {
    const response = await editStatusData(
      `/studio/premium-studio-status/${id}`
    );
    setChangeStatusPremiumStudio(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/studio/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [cities, setCities] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);

  const GetAllCities = async () => {
    const response = await getData("/allcity");
    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllCities();
  }, []);

  console.log("userData", user);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Booking Request"} link={"/booking-request"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-5">
                <div className="">
                  {/* container */}
                  <div className="row">
                    {/* {isAllow.includes(100) ? (
                      <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                        <Link
                          // to="/employee/employee_details/add"
                          type="button"
                          className="btn btn-add pe-3"
                        >
                          <div className="add me-3">
                            <div onClick={() => handleShow()}>
                              <p className="add-sub-txttt">
                                {" "}
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Studio
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                S. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                customer Name
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Vendor
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Studio Name
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Date
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Time
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Amount
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>{" "}
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col8")}
                                href="#"
                              >
                                Booking Accept Or Reject
                                {visible?.col8 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="row">
                          <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="sowing me-2">
                              <p className="show mt-2">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="num me-2 text-end">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="customer name"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          {usertype == "Admin" ? (
                            <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="num me-2 text-end">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  placeholder="vendor"
                                  value={vender}
                                  onChange={(e) => {
                                    setvender(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="num me-2 text-end">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="studio name"
                                value={studioName}
                                onChange={(e) => {
                                  setStudioName(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          {/* <div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}
                        </div>

                        <div className="row">
                          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-12 mb-2">
                            <Form.Label>Daily From</Form.Label>
                            <div className="num me-2 text-end">
                              <input
                                type="date"
                                className="form-control"
                                value={fromDaily}
                                onChange={(e) => {
                                  setFromDaily(e.target.value);
                                  setToDaily(""); // Reset the "Daily To" date when "Daily From" changes
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-12 mb-2">
                            <Form.Label>Daily To</Form.Label>
                            <div className="num me-2 text-end">
                              <input
                                type="date"
                                className="form-control"
                                value={toDaily}
                                onChange={(e) => setToDaily(e.target.value)}
                                min={fromDaily}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-2 col-12 my-auto ">
                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setSearch("");
                                  setStudioName("");
                                  setvender("");
                                  setTo("");
                                  setFrom("");
                                  setFromDaily("");
                                  setToDaily("");

                                  setReset(!resets);
                                }}
                                className="btn btn-reset w-100"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">S. No.</th>}
                            {visible.col2 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Customer Name
                              </th>
                            )}
                            {visible.col3 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Vendor
                              </th>
                            )}
                            {visible.col4 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Studio Name
                              </th>
                            )}
                            {/* {visible.col4 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Booking Type
                              </th>
                            )} */}
                            {visible.col5 && (
                              <th
                                style={{ width: "1000px" }}
                                className="tax-name"
                              >
                                Booking Date
                              </th>
                            )}
                            {visible.col6 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Booking Time
                              </th>
                            )}
                            {visible.col7 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Amount
                              </th>
                            )}{" "}
                            {visible.col9 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Subscribe Payment
                              </th>
                            )}
                            {/* {visible.col8 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Booking Status
                              </th>
                            )}{" "} */}
                            {visible.col8 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Booking Accept Or Reject
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data?.data?.map((d, index) => {
                            const currentDateTime = new Date();
                            const startDate = new Date(
                              d?.start_date || d?.date
                            );
                            const startTime = new Date(d?.start_time);
                            const endDate = new Date(d?.end_date || d?.date);
                            const endTime = new Date(d?.end_time);

                            return (
                              <tr key={index}>
                                {visible.col1 && <td>{index + 1}.</td>}
                                {visible.col2 && (
                                  <td>{d?.basic_information?.name}</td>
                                )}
                                {visible.col3 && (
                                  <td>{d?.studio?.user?.first_name}</td>
                                )}
                                {visible.col4 && <td>{d?.studio?.name}</td>}
                                {/* {visible.col4 && <td>{d?.type}</td>} */}
                                {visible.col5 && (
                                  <td>
                                    {d?.date
                                      ? d?.date
                                      : `${d?.start_date} - ${d?.end_date}`}
                                  </td>
                                )}
                                {visible.col6 && (
                                  <td>
                                    {d?.start_time} - {d?.end_time}
                                  </td>
                                )}
                                {visible.col7 && (
                                  <td>
                                    ₹{d?.amount ? Math.ceil(d.amount) : ""}
                                  </td>
                                )}
                                {visible.col9 && (
                                  <td>
                                    {d?.after_subscription_date ? "Yes" : "No"}
                                  </td>
                                )}

                                {/* Action Select Section */}
                                {visible.col8 & isAllow?.includes(135) ? (
                                  <>
                                    {usertype == "Admin" ? (
                                      d.stage
                                    ) : (
                                      <Col md={12}>
                                        <div className="main-form-section mt-3">
                                          <Form.Group>
                                            <Form.Label>
                                              Take an action
                                            </Form.Label>
                                            <InputGroup>
                                              <Controller
                                                name={`stage_${d.id}`}
                                                control={control}
                                                rules={{
                                                  required: "Take an Action",
                                                }}
                                                render={({ field }) => (
                                                  <Select
                                                    styles={{
                                                      control: (
                                                        baseStyles
                                                      ) => ({
                                                        ...baseStyles,
                                                        borderColor:
                                                          errors.stage
                                                            ? "red"
                                                            : baseStyles.borderColor,
                                                      }),
                                                    }}
                                                    onChange={(
                                                      selectedOption
                                                    ) => {
                                                      field.onChange(
                                                        selectedOption
                                                      ); // Update form state
                                                      ChangeStatus(
                                                        d.id,
                                                        selectedOption
                                                      ); // Call ChangeStatus with selected option
                                                    }}
                                                    value={field.value}
                                                    options={[
                                                      {
                                                        value: "Accepted",
                                                        label: "Accepted",
                                                      },
                                                      {
                                                        value: "Rejected",
                                                        label: "Rejected",
                                                      },
                                                    ]}
                                                  />
                                                )}
                                              />
                                            </InputGroup>
                                            {errors?.stage && (
                                              <span className="text-danger">
                                                {errors.stage.message}
                                              </span>
                                            )}
                                          </Form.Group>
                                        </div>
                                      </Col>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
