import React from "react";
import ReactApexChart from "react-apexcharts";

function DemoChart({ productData, productCount }) {
  // Define the series and options for the chart
  const series = [
    {
      name: "Booking",
      data: productCount, // The values for each product category
    },
  ];

  var options = {
    series: [25, 15, 44, 55, 41, 17],
    chart: {
      width: "100%",
      height: "100%",
      type: "pie",
    },
    labels: productData,
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [val];
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default DemoChart;
