import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import plus from "../../Components/assets/icons/a1.png";
import colunms from "../../Components/assets/icons/LINES.png";
import pen from "../../Components/assets/icons/pen.png";
import basket from "../../Components/assets/icons/basket.png";
import search1 from "../../Components/assets/icons/search.png";
import top from "../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../assets/icons/circle.png";
import rigth from "../assets/icons/rigth.png";
import save from "../assets/icons/save.png";
import cancel from "../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";
import { Button, Form } from "react-bootstrap";
import { formatDate } from "../../utils/common";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    getDownloadDataExcel,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [emailsearch, setemailSearch] = useState("");
  const [contactsearch, setcontactSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  const getDataAll = async () => {
    const response = await getData(
      `/user-subscription?page=${currentPage}&per_page=${perPage}&term=${search}&email=${emailsearch}&contact_no=${contactsearch}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    // setemailSearch(response?.data?.email);
    // setcontactSearch(response?.data?.contact_no);

    // setCurrentPage(response?.data?.);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/user-subscription/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/user-subscription/${recordToDeleteId}`
      );
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/contact-us/download",
          selectAllChecked,
          "Contact us list"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"User Subscription List"} link={"/home/ad-banner"} />
        <section className="AdvanceDashboard">
          <div className="row ">
            <div className="col-xxl-12 col-xl-12  ">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio ">
                  <div className="">
                    <div className="row">
                      <div className="row">
                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                          <div className="add me-1">
                            <div className="dropdown">
                              <button
                                className="btn btn-columns dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={colunms}
                                  className="columns me-2 "
                                  alt=""
                                />{" "}
                                Column Selection{" "}
                                <img src={top} className="top ms-1" alt="" />{" "}
                              </button>
                              <ul className="dropdown-menu">
                                {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col0")}
                              href="#"
                            >
                              Check Boxs
                              {visible?.col0 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col1")
                                    }
                                    href="#"
                                  >
                                    S. No
                                    {visible?.col1 ? (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col2")
                                    }
                                    href="#"
                                  >
                                    Name
                                    {visible?.col2 ? (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col3")
                                    }
                                    href="#"
                                  >
                                    Email
                                    {visible?.col3 ? (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col8")
                                    }
                                    href="#"
                                  >
                                    Contact No
                                    {visible?.col8 ? (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col5")
                                    }
                                    href="#"
                                  >
                                    Message
                                    {visible?.col5 ? (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-2"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={(event) =>
                                      toggleColumn(event, "col6")
                                    }
                                    href="#"
                                  >
                                    Date
                                    {visible?.col6 ? (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="ms-4"
                                        icon="fa-solid fa-eye-slash"
                                      />
                                    )}
                                  </Link>
                                </li>

                                {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Status
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}
                                {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Action
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>

                        {/* <div className="add">
                          <Button
                            variant="success"
                            className="btn btn-view"
                            onClick={HandleDownload}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon="fa-solid fa-download"
                              className="me-2"
                            />
                            Download
                          </Button>
                        </div> */}

                        {/* <div className="d-flex col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                          <button
                            type="button"
                            className="btn btn-search w-100"
                            onClick={HandleDownload}
                          >
                            Download data in Excel
                            <FontAwesomeIcon
                              icon="fa-solid fa-file-lines"
                              className="pdf-icon ms-3"
                              variant="success"
                              // className="btn btn-view"
                            />
                          </button>
                        </div> */}
                      </div>
                      <div className="row mt-4">
                        <div className="  col-lg-12 col-md-12 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12  col-md-12 col-12">
                          <div className="row">
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="sowing me-2">
                                <p className="show mt-2">{`Showing ${Math.min(
                                  (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                  currentPage * perPage,
                                  data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="num me-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name/email/contact no"
                                  id=""
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>{" "}
                            {/* <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="num me-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="email"
                                  id=""
                                  value={emailsearch}
                                  onChange={(e) => {
                                    setemailSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>{" "} */}
                            {/* <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="num me-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="contact no"
                                  id=""
                                  value={contactsearch}
                                  onChange={(e) => {
                                    setcontactSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>{" "} */}
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="Search me-2">
                                <button
                                  type="button"
                                  onClick={getDataAll}
                                  className="btn btn-search w-100"
                                >
                                  <img
                                    src={search1}
                                    className="search"
                                    alt=""
                                  />
                                </button>
                              </div>
                            </div>{" "}
                            <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                              <div className="Search-1">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSearch("");
                                    setemailSearch("");
                                    setcontactSearch("");
                                    setReset(!reset);
                                  }}
                                  className="btn btn-reset w-100 "
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {/* {visible.col0 && (
                                <th className="check round-check">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="selectAll"
                                      checked={
                                        allChecked.length ===
                                        selectAllChecked.length
                                      }
                                      onChange={handleChange}
                                      id="selectAll"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckChecked"
                                    ></label>
                                  </div>
                                </th>
                              )} */}
                              {visible.col1 && <th className="sr">S. No</th>}
                              {visible.col2 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "400px" }}
                                >
                                  Name
                                </th>
                              )}
                              {visible.col3 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "400px" }}
                                >
                                  Email
                                </th>
                              )}
                              {visible.col4 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "400px" }}
                                >
                                  Contact No
                                </th>
                              )}

                              {visible.col5 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "600px" }}
                                >
                                  Subscription Name
                                </th>
                              )}
                              {visible.col6 && (
                                <th className="tax-name">Subscription Price</th>
                              )}
                              {visible.col7 && (
                                <th className="tax-name">Subscription Date</th>
                              )}

                              {visible.col8 && (
                                <th className="tax-name">
                                  Subscription Expiry Date
                                </th>
                              )}

                              {visible.col9 && (
                                <th className="tax-name">
                                  Premium booking Count
                                </th>
                              )}

                              {visible.col10 && (
                                <th className="tax-name">
                                  Rechedule booking Count
                                </th>
                              )}
                              {/* {visible.col4 && <th className="active">Action</th>} */}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  {/* {visible.col0 && (
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={d?.id}
                                          name="perselected"
                                          checked={selectAllChecked.includes(
                                            d?.id
                                          )}
                                          onChange={handleChange}
                                          id="flexCheckDefault"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckDefault"
                                        ></label>
                                      </div>
                                    </td>
                                  )} */}
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && (
                                    <td>{d?.user?.first_name}</td>
                                  )}
                                  {visible.col3 && <td>{d?.user?.email}</td>}
                                  {visible.col4 && (
                                    <td>{d?.user?.contact_no}</td>
                                  )}
                                  {visible.col5 && (
                                    <td>{d?.subscription?.name}</td>
                                  )}{" "}
                                  {visible.col6 && <td>₹ {d?.price}</td>}
                                  {visible.col7 && (
                                    <td>{formatDate(d?.createdAt)}</td>
                                  )}{" "}
                                  {visible.col8 && <td>{d?.expiry_date}</td>}
                                  {visible.col9 && (
                                    <td>{d?.premium_studio_count}</td>
                                  )}
                                  {visible.col10 && (
                                    <td>{d?.rechedule_booking_count}</td>
                                  )}
                                  {/* {visible.col3 && (
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={d.status}
                                      onChange={() => {
                                        ChangeStatus(d.id);
                                      }}
                                      id={`flexSwitchCheckDefault${d?.id}`}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                    >
                                      {d.status ? "Active" : "Inactive"}
                                    </label>
                                  </div>
                                </td>
                              )} */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
