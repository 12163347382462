import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import plus from "../../Components/assets/icons/a1.png";
import colunms from "../../Components/assets/icons/LINES.png";
import pen from "../../Components/assets/icons/pen.png";
import eye from "../../Components/assets/icons/eye.png";
import basket from "../../Components/assets/icons/basket.png";
import search1 from "../../Components/assets/icons/search.png";
import top from "../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../assets/icons/circle.png";
import rigth from "../assets/icons/rigth.png";
import save from "../assets/icons/save.png";
import cancel from "../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../utils/context";
import { FaEye } from "react-icons/fa";
import ModalSave from "../common/ModelSave";

library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    IMG_URL,
    Per_Page_Dropdown,
    Select2Data,
    usertype,
  } = useContext(Context);

  console.log(usertype, "usertype");

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchByUser, setSearchByUser] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchChildSubCategory, setSearchChildSubCategory] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setSearchByUser(id || null); // Set to null if id is undefined or null
    getDataAll();
  }, [id]);

  useEffect(() => {
    getDataAll();
  }, [searchByUser]);

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  console.log(showModal, "showModal");

  const [changeStatus, setChangeStatus] = useState();
  const [changeStatusTopStudio, setChangeStatusTopStudio] = useState();
  const [changeStatusPremiumStudio, setChangeStatusPremiumStudio] = useState();

  const getDataAll = async () => {
    let url = `/studio?page=${currentPage}&per_page=${perPage}&term=${search}&searchCategory=${
      searchCategory?.value || ""
    }&searchSubCategory=${
      searchSubCategory?.value || ""
    }&searchChildSubCategory=${searchChildSubCategory?.value || ""}`;

    if (id) {
      url = `/studio?page=${currentPage}&per_page=${perPage}&term=${search}&searchByUser=${id}&searchCategory=${
        searchCategory?.value || ""
      }&searchSubCategory=${
        searchSubCategory?.value || ""
      }&searchChildSubCategory=${searchChildSubCategory?.value || ""}`;
    }

    const response = await getData(url);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    console.log("response", response);
  };

  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    changeStatusTopStudio,
    changeStatusPremiumStudio,
    perPage,
    reset,
    show,
    show1,
    search,
    searchCategory,
    searchSubCategory,
    searchChildSubCategory,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/studio/${id}`);
    setChangeStatus(response);
  };

  const ChangeStatusTopStudio = async (id) => {
    const response = await editStatusData(`/studio/top-studio-status/${id}`);
    setChangeStatusTopStudio(response);
  };

  const ChangeStatusPremiumStudio = async (id) => {
    const response = await editStatusData(
      `/studio/premium-studio-status/${id}`
    );
    setChangeStatusPremiumStudio(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/studio/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [cities, setCities] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);

  const GetAllCities = async () => {
    const response = await getData("/allcity");
    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllCities();
  }, []);

  console.log("userData", user);

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Studio"} link={"/studio"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="row">
                      {usertype === "vendor" && isAllow.includes(100) ? (
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                          <Link
                            // to="/employee/employee_details/add"
                            type="button"
                            className="btn btn-add pe-3"
                          >
                            <div className="add">
                              <div onClick={() => handleShow()}>
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Studio
                              </div>
                            </div>
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="add">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={colunms}
                                className="columns me-2 "
                                alt=""
                              />
                              Column Selection{" "}
                              <img src={top} className="top ms-1" alt="" />{" "}
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col1")
                                  }
                                  href="#"
                                >
                                  S. No
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col2")
                                  }
                                  href="#"
                                >
                                  Studio Name
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col4")
                                  }
                                  href="#"
                                >
                                  Address
                                  {visible?.col4 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col5")
                                  }
                                  href="#"
                                >
                                  City
                                  {visible?.col5 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Image
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}

                              {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Manufacturer
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col8")
                                  }
                                  href="#"
                                >
                                  Status
                                  {visible?.col8 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col10")
                                  }
                                  href="#"
                                >
                                  Top Studio
                                  {visible?.col10 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col11")
                                  }
                                  href="#"
                                >
                                  Premium Studio
                                  {visible?.col11 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col9")
                                  }
                                  href="#"
                                >
                                  Action
                                  {visible?.col9 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="row">
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="sowing me-2">
                              <p className="show mt-2">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                          </div>
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="num me-2">
                              <Select
                                isSearchable
                                options={cities}
                                value={searchCategory}
                                placeholder="Select City"
                                onChange={(e) => {
                                  setSearchCategory(e);
                                  //   GetAllSubCategory(e.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="num me-2 text-end">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Name/Address"
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          {/* <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="num me-2 text-end">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Address"
                                id=""
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </div> */}
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2">
                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setSearch("");
                                  setSearchCategory("");
                                  setSearchSubCategory("");
                                  setSearchChildSubCategory("");
                                  setReset(!reset);
                                }}
                                className="btn btn-reset w-100"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                          {/* </div> <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-2 col-12 mb-2"><div className="Search me-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">S. No</th>}
                            {visible.col2 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Studio Name
                              </th>
                            )}

                            {visible.col4 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Address
                              </th>
                            )}
                            {visible.col5 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                City
                              </th>
                            )}
                            {/* {visible.col6 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Image
                              </th>
                            )} */}
                            {visible.col7 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Stage
                              </th>
                            )}
                            {/* {visible.col6 && (
                            <th className="tax-name">Password</th>
                          )} */}
                            {visible.col8 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Status
                              </th>
                            )}
                            {visible.col10 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Top Studio
                              </th>
                            )}
                            {visible.col11 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Premium Studio
                              </th>
                            )}
                            {visible.col9 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                {user?.id === 1 ? "Action " : "Action "}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col2 && <td>{d?.name}</td>}

                                {visible.col4 && <td>{d?.address}</td>}
                                {visible.col5 && <td>{d?.city?.name}</td>}
                                {/* {visible.col6 && (
                                  <td>
                                    {d?.image && (
                                      <img
                                        src={IMG_URL + d?.image}
                                        alt="Image"
                                        width="100"
                                        height="100"
                                      />
                                    )}
                                  </td>
                                )} */}
                                {visible.col7 && <td>{d?.stage}</td>}
                                {/* {visible.col6 && <td>{d?.password}</td>} */}
                                {/* {visible.col6 && <td>******</td>} */}
                                {visible.col8 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={d.status}
                                        // disabled={!isAllow?.includes(89)}
                                        onChange={() => {
                                          ChangeStatus(d.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d.status ? "Active" : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                )}
                                {visible.col10 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={d.top_studio_status}
                                        disabled={usertype == "vendor"}
                                        onChange={() => {
                                          ChangeStatusTopStudio(d.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d.top_studio_status
                                          ? "Active"
                                          : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                )}
                                {visible.col11 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={d.premium_studio_status}
                                        disabled={usertype == "vendor"}
                                        onChange={() => {
                                          ChangeStatusPremiumStudio(d.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d.premium_studio_status
                                          ? "Active"
                                          : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                )}
                                {visible.col9 && (
                                  <td>
                                    {/* {d?.id === 1 ? (
                                      <></>
                                    ) : ( */}
                                    <div className="d-flex">
                                      {/* {isAllow.includes(89) ? ( */}
                                      <Button
                                        onClick={() => handleShow1(d?.id)}
                                        type="button"
                                        className="btn btn-primary me-1"
                                      >
                                        {user?.id === 1 ? (
                                          <img
                                            src={eye}
                                            className="pen"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={pen}
                                            className="pen"
                                            alt=""
                                          />
                                        )}
                                        {/* <img src={pen} className="pen" alt="" /> */}
                                      </Button>
                                      {/* ) : (
                                        <></>
                                      )} */}

                                      {user?.id !== 1 ? (
                                        <button
                                          onClick={() => {
                                            showDeleteRecord(d?.id, d?.name);
                                          }}
                                          type="button"
                                          className="btn btn-danger"
                                        >
                                          <img
                                            src={basket}
                                            className="pen"
                                            alt=""
                                          />
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {/* )} */}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      ) : (
        ""
      )}

      <ModalSave
        show={showModal}
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
