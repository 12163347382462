import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Context } from "../../../../utils/context";

function DonutDemoPart2(props) {
  const [appSetup, setAppSetup] = useState("");

  console.log(appSetup?.platform_charges, "appSetup");

  const sellerAmounts = props?.sellerAmountGraphCount?.map(
    (amount) => amount - (amount * appSetup?.platform_charges) / 100
  );
  console.log(sellerAmounts, "selleramountttttttttttttttttttttttt");

  const { getData, usertype } = useContext(Context);
  const GetAppSetup = async (days) => {
    try {
      const res = await getData(`/all-app-setup`);
      setAppSetup(res?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    GetAppSetup();
  }, []);
  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: props?.sellerAmountForGraph, // Seller names as labels
    series: props?.sellerAmountGraphCount, // Data values
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "16px",
            },
            value: {
              show: true,
              fontSize: "14px",
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const amount = opts.w.config.series[opts.seriesIndex];
        return `₹${amount}`; // Show the amount inside the chart
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={options.series}
          type="donut"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default DonutDemoPart2;
